export const CONNECTION_GAMES =
[
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cleveland Indians",
            "words": [
                "Bob Allen",
                "Brad Arnsberg",
                "Keith Atherton",
                "Brandon Barnes"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Houston Astros",
            "words": [
                "Dave Adlesh",
                "Buddy Biancalana",
                "Travis Buck",
                "Mark Davidson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Colorado Rockies",
            "words": [
                "Matt Anderson",
                "David Cortés",
                "Jerry Dipoto",
                "Butch Huskey"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Philadelphia Phillies",
            "words": [
                "Darrel Akerfelds",
                "Brian Bogusevic",
                "Domonic Brown",
                "Dave Cole"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Montreal Expos",
            "words": [
                "John Dopson",
                "Barry Foote",
                "Terry Francona",
                "Bob James"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Bobby Murcer",
                "Andy Pettitte",
                "José Rijo",
                "Mariano Rivera"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Baltimore Orioles",
            "words": [
                "Darnell McDonald",
                "Larry Sheets",
                "Hal Smith",
                "Mark Smith"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Detroit Tigers",
            "words": [
                "Tom Seats",
                "Chris Shelton",
                "Marlin Stuart",
                "John Sullivan"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Colorado Rockies: 16",
            "words": [
                "Denny Hocking",
                "Huston Street",
                "Adam Melhuse",
                "José Jiménez"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Minnesota Twins: 2",
            "words": [
                "Randy Bass",
                "Frank Kostro",
                "Graig Nettles",
                "Pat Meares"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Kansas City Royals: 7",
            "words": [
                "Grégor Blanco",
                "Jeff King",
                "A.J. Hinch",
                "Todd Dunwoody"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Montreal Expos: 41",
            "words": [
                "Randy Bass",
                "Steve Frey",
                "Jeff Reardon",
                "Ugueth Urbina"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Atlanta Braves",
            "words": [
                "Jeff Dedmon",
                "Bob Didier",
                "John Ennis",
                "John Gant"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cincinnati Reds",
            "words": [
                "Joel Youngblood",
                "Dan Driessen",
                "Tommy Harper",
                "Jay Howell"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago White Sox",
            "words": [
                "Lu Blue",
                "Jim Busby",
                "Bruce Campbell",
                "Neal Cotts"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Baltimore Orioles",
            "words": [
                "Mickey McGuire",
                "John Miller",
                "Paul Mitchell",
                "Carl Nichols"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1997",
            "words": [
                "José Guillén",
                "Fernando Tatís",
                "Orlando Cabrera",
                "Derrek Lee"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2011",
            "words": [
                "Chase d'Arnaud",
                "Danny Farquhar",
                "Brandon Guyer",
                "George Kontos"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2012",
            "words": [
                "Blake Parker",
                "Hernán Pérez",
                "AJ Ramos",
                "Nick Vincent"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1932",
            "words": [
                "Paul Dixon",
                "Leo Hannibal",
                "Bert Johnson",
                "Slim Jones"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Browns",
            "words": [
                "Hank Edwards",
                "Lou Koupal",
                "Harry Brecheen",
                "Marty Marion"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Boston Red Sox",
            "words": [
                "Shane Andrews",
                "Rocco Baldelli",
                "Boze Berger",
                "Tom Brewer"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Toronto Blue Jays",
            "words": [
                "Tom Murphy",
                "Clayton Richard",
                "Tony Solaita",
                "Pat Tabler"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Houston Astros",
            "words": [
                "Runelvys Hernández",
                "Matt Kata",
                "Jim Mahoney",
                "Gary Majewski"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Tack Summers",
                "Dilson Herrera",
                "Keith Mitchell",
                "Troy Afenir"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Tex Erwin",
                "Clint Hartung",
                "Greg Aquino",
                "Harvey Pulliam"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 11 seasons",
            "words": [
                "Bob Robertson",
                "Jake Powell",
                "Dee Strange-Gordon",
                "Dickie Noles"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 18 seasons",
            "words": [
                "Bobby Lowe",
                "Pop Snyder",
                "Lloyd Waner",
                "Rick Cerone"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Houston Astros",
            "words": [
                "Johnny Estrada",
                "Stan Papi",
                "Scott Loucks",
                "Bobby Clark"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Mets",
            "words": [
                "Jason Isringhausen",
                "David West",
                "Rafael Palmeiro",
                "Pat Putnam"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Arizona Diamondbacks",
            "words": [
                "Lyle Overbay",
                "Jeff Manship",
                "Evan Marshall",
                "Jack Cust"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Milwaukee Braves",
            "words": [
                "Earl Williams",
                "Wayne Garrett",
                "Duffy Dyer",
                "Ken Tatum"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Sammy Taylor",
                "Ralph Winegarner",
                "Jeff Robinson",
                "Doug Bochtler"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 9 seasons",
            "words": [
                "Mark Mulder",
                "Jon Niese",
                "Pat Kelly",
                "Jim Coates"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 22 seasons",
            "words": [
                "Randy Johnson",
                "Herb Pennock",
                "Phil Cavarretta",
                "Joe Morgan"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Louis Dula",
                "Yank Terry",
                "Clarence White",
                "Bruce Boisclair"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Dick Green",
                "John Boccabella",
                "Ryan Ludwick",
                "Tommy Thomas"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Marc Sullivan",
                "Todd Haney",
                "Matt Daley",
                "Bert Johnson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 9 seasons",
            "words": [
                "Tommy Gregg",
                "Jorge Julio",
                "Danny Coombs",
                "Bill Travers"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 14 seasons",
            "words": [
                "Larry Herndon",
                "Pete Runnels",
                "Jeffrey Leonard",
                "John Anderson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Atlanta Braves: 37",
            "words": [
                "Bob Priddy",
                "Ron Schueler",
                "Jo-Jo Reyes",
                "Chris Reitsma"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Atlanta Braves: 16",
            "words": [
                "Craig Robinson",
                "Brian McCann",
                "Sonny Jackson",
                "Sandy Alomar"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Atlanta Braves: 25",
            "words": [
                "Danny Frisella",
                "Gary Gentry",
                "Troy Glaus",
                "Mike Kelly"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Pittsburgh Pirates: 12",
            "words": [
                "John Wehner",
                "Corey Hart",
                "Matt Alexander",
                "Adam Comorosky"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Diego Padres: 30",
            "words": [
                "Derrel Thomas",
                "Eric Show",
                "Héctor Torres",
                "Heath Bell"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Seattle Mariners: 41",
            "words": [
                "Felipe Lira",
                "Doc Medich",
                "Mike Kekich",
                "Dave Tobik"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Colorado Rockies: 55",
            "words": [
                "Felipe Paulino",
                "Danny Ardoin",
                "Mark Grant",
                "Eliézer Alfonzo"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Toronto Blue Jays: 30",
            "words": [
                "Mike Morgan",
                "Dioner Navarro",
                "Doug Linton",
                "Taylor Buchholz"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Mets",
            "words": [
                "Robert Person",
                "Charlie Puleo",
                "Ronn Reynolds",
                "Leroy Stanton"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Joe Lefebvre",
                "Phil Linz",
                "Tim Lollar",
                "Bob Meyer"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago White Sox",
            "words": [
                "Jack Hallett",
                "Rich Hinton",
                "Gregory Infante",
                "Bart Johnson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Boston Braves",
            "words": [
                "Dick Donovan",
                "Elbie Fletcher",
                "Sam Jethroe",
                "Eddie Mathews"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Kansas City Royals: 39",
            "words": [
                "Felipe Paulino",
                "Al Fitzmorris",
                "Doug Bochtler",
                "Al Hrabosky"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Detroit Tigers: 26",
            "words": [
                "Roxie Lawson",
                "Vance Wilson",
                "Doug Brocail",
                "Joe Ginsberg"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Yankees: 22",
            "words": [
                "Ruppert Jones",
                "Mike Fischlin",
                "Roger Clemens",
                "Darrell Johnson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cincinnati Reds: 27",
            "words": [
                "Eddie Lukon",
                "Scott Rolen",
                "Lloyd Waner",
                "Pete Whisenant"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Houston Astros",
            "words": [
                "Wilbur Howard",
                "Joe Inglett",
                "Jonathan Johnson",
                "Pat Listach"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cleveland Indians",
            "words": [
                "Wilson Ramos",
                "Kevin Seitzer",
                "John Smiley",
                "Al Smith"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Atlanta Braves",
            "words": [
                "Ken Caminiti",
                "Jim Clancy",
                "Freddy García",
                "Cole Hamels"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Milwaukee Brewers",
            "words": [
                "Rocky Coppinger",
                "John Felske",
                "Jared Fernández",
                "Alfredo Fígaro"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Colorado Rockies",
            "words": [
                "Bobby Jones",
                "Bryan Rekar",
                "Ian Stewart",
                "Juan Acevedo"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Philadelphia Phillies",
            "words": [
                "Art Mahaffey",
                "Michael Martínez",
                "Len Matuszek",
                "John Mayberry"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for California Angels",
            "words": [
                "Rubén Amaro",
                "Dan Briggs",
                "Pat Clements",
                "Mike Cook"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Johnny Blanchard",
                "Mike Blowers",
                "Andy Carey",
                "Spud Chandler"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 15 seasons",
            "words": [
                "Bob Shawkey",
                "Ken Holtzman",
                "Floyd Bannister",
                "Omar Infante"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Jim Lyttle",
                "LeRoy Taylor",
                "Eddie Leon",
                "Johnny Hetki"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Mike Baxter",
                "Hugh Daily",
                "Garrett Olson",
                "Dib Williams"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Craig Worthington",
                "Johnny Miljus",
                "Dave Rucker",
                "Chad Cordero"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago Cubs",
            "words": [
                "Johnny Goryl",
                "Adam Greenberg",
                "Dave Hillman",
                "Glen Hobbie"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Bobby Brown",
                "Phil Coke",
                "Jerry Coleman",
                "Dusty Cooke"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Toronto Blue Jays",
            "words": [
                "Jeff Kent",
                "John Olerud",
                "Pat Borders",
                "Mike Timlin"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Pittsburgh Pirates",
            "words": [
                "David Williams",
                "Craig Wilson",
                "Harry Bright",
                "Matt Capps"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the St. Louis Cardinals",
            "words": [
                "Mike Jeffcoat",
                "Curt Ford",
                "Braden Looper",
                "Mark Salas"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago Cubs",
            "words": [
                "Matt Franco",
                "Dan Winkler",
                "Terry Francona",
                "Adam Everett"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Toronto Blue Jays",
            "words": [
                "Erik Kratz",
                "Lloyd Moseby",
                "Greg Myers",
                "Travis Snider"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Kansas City Royals",
            "words": [
                "Bryan Bullington",
                "Will Clark",
                "Brent Mayne",
                "Dennis Leonard"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Toronto Blue Jays",
            "words": [
                "Glenallen Hill",
                "César Izturis",
                "Reed Johnson",
                "Brandon Lyon"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Vic Raschi",
                "Tony Rensa",
                "Bill Short",
                "Charlie Silvera"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Texas Rangers",
            "words": [
                "Bob Jones",
                "Gerald Laird",
                "Ryan Ludwick",
                "Paul Mirabella"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Pittsburgh Pirates",
            "words": [
                "Mike Bielecki",
                "Rajai Davis",
                "Bobby Del Greco",
                "Larry French"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago White Sox",
            "words": [
                "Bob Locker",
                "Donn Pall",
                "Johnny Rigney",
                "Aaron Rowand"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for San Diego Padres",
            "words": [
                "Chris Welsh",
                "Kyle Blanks",
                "Juan Bonilla",
                "Everth Cabrera"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Atlanta Braves",
            "words": [
                "Dusty Baker",
                "Ron Reed",
                "Mike Stanton",
                "Chipper Jones"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Montreal Expos",
            "words": [
                "Balor Moore",
                "Jeff Parrett",
                "Randy St. Claire",
                "Ugueth Urbina"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Kansas City Royals",
            "words": [
                "Enny Romero",
                "Billy Sorrell",
                "George Spriggs",
                "Carl Taylor"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cincinnati Reds",
            "words": [
                "Hank Johnson",
                "Willie Jones",
                "Clyde Manion",
                "Jack Ogden"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Oakland Athletics",
            "words": [
                "Michael Wuertz",
                "Chris Bando",
                "Rich Bordi",
                "Bill Caudill"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cleveland Indians",
            "words": [
                "Bob Feller",
                "Frank Robinson",
                "Harold Baines",
                "Quincy Trouppe"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Washington Nationals",
            "words": [
                "Stephen Strasburg",
                "Marco Estrada",
                "Alex Dickerson",
                "Ryan Buchter"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the St. Louis Cardinals",
            "words": [
                "Gary Gaetti",
                "Brad Thompson",
                "Dan Plesac",
                "Jason Motte"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Tampa Bay Devil Rays",
            "words": [
                "Kris Medlen",
                "Mike Fontenot",
                "Joey Gathright",
                "Reid Brignac"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Texas Rangers",
            "words": [
                "Tanner Roark",
                "Eddie Miller",
                "Barry Jones",
                "Rich Aurilia"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Baltimore Orioles",
            "words": [
                "Ernie Whitt",
                "Hank Majeski",
                "Lonnie Smith",
                "Mike Flanagan"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago White Sox",
            "words": [
                "Ron Kittle",
                "John Kruk",
                "Barry Lyons",
                "Joe Magrane"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Philadelphia Phillies",
            "words": [
                "Ken Ryan",
                "Hal Spindel",
                "Vito Tamulis",
                "Frank Torre"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Yankees",
            "words": [
                "Jim Turner",
                "Pete Ward",
                "Scott Bankhead",
                "Wade Blasingame"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Pittsburgh Pirates: 6",
            "words": [
                "Mike Benjamin",
                "Rafael Belliard",
                "Tony Peña",
                "Johnny O'Brien"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Seattle Mariners: 18",
            "words": [
                "Joe Lis",
                "Joe Oliver",
                "Russ Davis",
                "Joe Simpson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Detroit Tigers: 12",
            "words": [
                "Glenn Wilson",
                "Jarrod Saltalamacchia",
                "Ed Romero",
                "Mike Brumley"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cleveland Indians: 60",
            "words": [
                "Brian Tallet",
                "Bill Selby",
                "Kane Davis",
                "Jhonny Peralta"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Mike Oquist",
                "Ed Steele",
                "Tom Wilhelmsen",
                "Jacob Turner"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Lou Clinton",
                "Ken Ryan",
                "Ronny Paulino",
                "Dennis Lewallyn"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Cy Falkenberg",
                "Gary Bell",
                "Tom Browning",
                "Steve Hargan"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Myron Allen",
                "Jack Wadsworth",
                "Brian Sweeney",
                "Fay Washington"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Philadelphia Athletics",
            "words": [
                "John Jones",
                "Bill Knickerbocker",
                "Roberto Ortiz",
                "Oscar Roettger"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cincinnati Reds",
            "words": [
                "Jesús Sánchez",
                "José Silva",
                "Dave Sisler",
                "Ricky Stone"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Diego Padres",
            "words": [
                "Charles Nagy",
                "Wil Nieves",
                "Vicente Palacios",
                "Craig Stammen"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Arizona Diamondbacks",
            "words": [
                "Brad Ziegler",
                "Danny Bautista",
                "Kris Benson",
                "Chris Donnels"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Mets: 46",
            "words": [
                "Tyler Walker",
                "Barry Jones",
                "Tyler Clippard",
                "Óliver Pérez"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Colorado Rockies: 10",
            "words": [
                "Mike Hampton",
                "Cristhian Adames",
                "Dante Bichette",
                "Royce Clayton"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Colorado Rockies: 18",
            "words": [
                "Bruce Ruffin",
                "Mike DeJean",
                "Cristhian Adames",
                "Jonathan Herrera"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Francisco Giants: 14",
            "words": [
                "Cristhian Adames",
                "Guillermo Quiroz",
                "Andrés Galarraga",
                "F.P. Santangelo"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Seattle Mariners: 13",
            "words": [
                "Chris Shelton",
                "Dick Pole",
                "Jeff Fassero",
                "Dustin Ackley"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Yankees: 29",
            "words": [
                "Mike Stanton",
                "Luis Aguayo",
                "Tim Redding",
                "Dustin Ackley"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Atlanta Braves: 46",
            "words": [
                "Randy St. Claire",
                "David Palmer",
                "Brad Brach",
                "Bob Priddy"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Mets: 36",
            "words": [
                "Wayne Twitchell",
                "Greg McMichael",
                "Juan Centeno",
                "Jerry Koosman"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the St. Louis Cardinals",
            "words": [
                "Kelly Paris",
                "Oddibe McDowell",
                "Jerry Mumphrey",
                "Terry Kennedy"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Tampa Bay Devil Rays",
            "words": [
                "Mike Pelfrey",
                "Jason Standridge",
                "James Shields",
                "Jake McGee"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Seattle Mariners",
            "words": [
                "Juan Pierre",
                "Charles Gipson",
                "Gil Meche",
                "Joe Slusarski"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Atlanta Braves",
            "words": [
                "Wes Helms",
                "Duane Ward",
                "Milt Thompson",
                "Craig McMurtry"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1975",
            "words": [
                "Chris Knapp",
                "Paul Mitchell",
                "Gary Alexander",
                "Mike Barlow"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2016",
            "words": [
                "Mike Freeman",
                "John Gant",
                "Brian Goodwin",
                "Jared Hoying"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1978",
            "words": [
                "Glenn Hubbard",
                "Keith Moreland",
                "Dwayne Murphy",
                "David Palmer"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1993",
            "words": [
                "Mike Hampton",
                "Trevor Hoffman",
                "Manny Ramirez",
                "Chipper Jones"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1938",
            "words": [
                "Charlie Parks",
                "Jim Tabor",
                "Ed Young",
                "Ted Alexander"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2002",
            "words": [
                "Duaner Sánchez",
                "So Taguchi",
                "Hank Blalock",
                "Kevin Cash"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1927",
            "words": [
                "Wilcy Moore",
                "Columbus Vance",
                "Carroll Yerkes",
                "Earl Clark"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1911",
            "words": [
                "Art Butler",
                "Roy Corhan",
                "Gene Dale",
                "Bill McTigue"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1953",
            "words": [
                "Tom Qualters",
                "Paul Smith",
                "Harry Hanebrink",
                "Johnny O'Brien"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2007",
            "words": [
                "Shelley Duncan",
                "Ben Francisco",
                "Alberto González",
                "Joel Hanrahan"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1937",
            "words": [
                "Newt Kimball",
                "Clarence Lamar",
                "Frank McAllister",
                "Archie McKain"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1991",
            "words": [
                "Chuck Knoblauch",
                "Mike Magnante",
                "Yorkis Pérez",
                "Armando Reynoso"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Baltimore Orioles",
            "words": [
                "Brian Holton",
                "J.R. House",
                "Jim Hutto",
                "Dave Leonhard"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Yankees",
            "words": [
                "Ken Holtzman",
                "Catfish Hunter",
                "John Mayberry",
                "Bob Muncrief"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cleveland Indians",
            "words": [
                "Keith Hernandez",
                "Don Newcombe",
                "Hal Newhouser",
                "Birdie Tebbetts"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Francisco Giants",
            "words": [
                "Lary Sorensen",
                "Leon Wagner",
                "Jim Barr",
                "Matt Cain"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Chris Michalak",
                "Russ Derry",
                "Duke Carmel",
                "Charley Wilson"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 9 seasons",
            "words": [
                "Scott Leius",
                "Grover Lowdermilk",
                "Lamar Johnson",
                "David Howard"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Larry Johnson",
                "Aaron Ledesma",
                "Ken Trinkle",
                "Vic Roznovsky"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 11 seasons",
            "words": [
                "Pete Smith",
                "Bobby Byrne",
                "Bob Boyd",
                "Billy Cox"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cincinnati Reds",
            "words": [
                "Jim Crowell",
                "Willie Greene",
                "Don Gullett",
                "Josh Hamilton"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Toronto Blue Jays",
            "words": [
                "Rob Ducey",
                "Mark Eichhorn",
                "Shawn Green",
                "Jimmy Key"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Anaheim Angels",
            "words": [
                "Derrick Turnbow",
                "Matt Wise",
                "Alfredo Amézaga",
                "Jamie Burke"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Detroit Tigers",
            "words": [
                "Wally Schang",
                "Dizzy Trout",
                "Lou Whitaker",
                "Ray Hayworth"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Los Angeles Dodgers",
            "words": [
                "Candy Maldonado",
                "Dennys Reyes",
                "Vicente Romo",
                "David Ross"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Texas Rangers",
            "words": [
                "Nick Masset",
                "Oddibe McDowell",
                "Robb Nen",
                "Nelson Norman"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Joe Glenn",
                "Bob Grim",
                "Sean Henn",
                "Ken Holcombe"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Britt Reames",
                "Hal Rice",
                "Emil Verban",
                "George Watkins"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Ken Johnson",
                "Wiley Piatt",
                "Joe Boley",
                "Jerry Goff"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Hack Wilson",
                "Steve Hamilton",
                "Ed FitzGerald",
                "Wilson Ramos"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Joel Hanrahan",
                "Pat Duncan",
                "Alex Cole",
                "Mike James"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Dave Campbell",
                "Dusty Cooke",
                "Tim Redding",
                "Brad Havens"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cleveland Indians",
            "words": [
                "Johnny Jeter",
                "Houston Jiménez",
                "Gary Kroll",
                "David Lee"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Los Angeles Angels of Anaheim",
            "words": [
                "Paul McAnulty",
                "Vinnie Pestano",
                "Reggie Willits",
                "Jason Bulger"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Mets",
            "words": [
                "Damion Easley",
                "Jay Bell",
                "David Cone",
                "Jeff Conine"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Boston Red Sox",
            "words": [
                "Jason Repko",
                "Hansel Robles",
                "Danny Santana",
                "José Santiago"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Giants",
            "words": [
                "Jo-Jo Moore",
                "Don Mueller",
                "Al Smith",
                "Daryl Spencer"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago Cubs",
            "words": [
                "Dick Drott",
                "Mike Hubbard",
                "Lou Jackson",
                "Roy Joiner"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Brooklyn Dodgers",
            "words": [
                "Don Newcombe",
                "Johnny Podres",
                "George Kelly",
                "Val Picinich"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Philadelphia Phillies",
            "words": [
                "Del Ennis",
                "Turk Farrell",
                "Mike Lieberthal",
                "Nick Punto"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 3 seasons",
            "words": [
                "Wei-Chung Wang",
                "Shairon Martis",
                "Charlie Petty",
                "Bill Warwick"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Bill LeFebvre",
                "Johnnie Oden",
                "Harry Decker",
                "Rod Craig"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Stephen Drew",
                "Ray Benge",
                "Joe Cunningham",
                "Kevin Millar"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Ed Jurak",
                "George Zettlein",
                "David Carpenter",
                "Bill Evans"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Browns",
            "words": [
                "Ollie Bejma",
                "Ray Coleman",
                "Bob Dillinger",
                "Jim Dyck"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Philadelphia Phillies",
            "words": [
                "Pat Corrales",
                "Larry Cox",
                "Bob Dernier",
                "Todd Frohwirth"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Seattle Mariners",
            "words": [
                "Gil Meche",
                "Brandon Morrow",
                "Eric O'Flaherty",
                "Michael Pineda"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Giants",
            "words": [
                "Bob O'Farrell",
                "Hoyt Wilhelm",
                "Dolf Luque",
                "Clarence Mitchell"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Boston Red Sox",
            "words": [
                "Michael Bowden",
                "Rick Miller",
                "Tino Martinez",
                "Mike Greenwell"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Mets",
            "words": [
                "Kirk Nieuwenhuis",
                "Charlie Williams",
                "Lastings Milledge",
                "Jon Matlack"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago Cubs",
            "words": [
                "Jim Tyrone",
                "Tim Lincecum",
                "Justin Speier",
                "Rick Wilkins"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Pittsburgh Pirates",
            "words": [
                "Milt May",
                "Jon Switzer",
                "Jeff Bennett",
                "Kevin Young"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Miami Marlins: 48",
            "words": [
                "Mike Morin",
                "Héctor Noesí",
                "Justin Bour",
                "Andrew Cashner"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Miami Marlins: 41",
            "words": [
                "David Phelps",
                "Gil Velazquez",
                "Justin Bour",
                "Chris Hatcher"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Phillies: 33",
            "words": [
                "Bo Belinsky",
                "Luis Aguayo",
                "Rheal Cormier",
                "Rubén Amaro"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Los Angeles Angels: 41",
            "words": [
                "Ron Kline",
                "Dean Chance",
                "Jack Spring",
                "Bob Meyer"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the California Angels",
            "words": [
                "Dave Chalk",
                "Jim Edmonds",
                "Jim Spencer",
                "Alan Wiggins"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Baltimore Orioles",
            "words": [
                "Al Osuna",
                "Tim Stauffer",
                "Luis Matos",
                "Mike Lansing"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the St. Louis Cardinals",
            "words": [
                "Allen Craig",
                "Yadier Molina",
                "Jeff Blauser",
                "Bill Caudill"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Toronto Blue Jays",
            "words": [
                "Russ Adams",
                "Anthony Alford",
                "Ryan Thompson",
                "Bob MacDonald"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Francisco Giants",
            "words": [
                "Guillermo Mota",
                "Ozzie Virgil",
                "Rich Aurilia",
                "Steve Barber"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Diego Padres",
            "words": [
                "Dick Kelley",
                "Jerry Manuel",
                "Don Mason",
                "Luis Meléndez"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago White Sox",
            "words": [
                "Sad Sam Jones",
                "Ted Lyons",
                "Red Ruffing",
                "Carlton Fisk"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Philadelphia Phillies",
            "words": [
                "Schoolboy Rowe",
                "Whit Wyatt",
                "Gus Mancuso",
                "Pedro Martinez"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Francisco Giants",
            "words": [
                "Steve Decker",
                "Gary Green",
                "Jay Canizaro",
                "David Aardsma"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Philadelphia Phillies",
            "words": [
                "Josh Outman",
                "Brian Cooper",
                "Wally Ritchie",
                "Gavin Floyd"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Pittsburgh Pirates",
            "words": [
                "Rick Sweet",
                "Paul Wilson",
                "Vance Law",
                "Bip Roberts"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Minnesota Twins",
            "words": [
                "Denny Neagle",
                "Scott Stahoviak",
                "Terry Bulling",
                "Chris Parmelee"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Mets",
            "words": [
                "Juan Centeno",
                "Mark Carreon",
                "John Milner",
                "Mike Kelly"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Pittsburgh Pirates",
            "words": [
                "Rick Renteria",
                "Bill Laxton",
                "Rich Sauveur",
                "Dave Dravecky"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Kansas City Royals",
            "words": [
                "Blaine Hardy",
                "George Brett",
                "Russ Morman",
                "John Morris"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Montreal Expos",
            "words": [
                "Marquis Grissom",
                "Doug Bochtler",
                "Kent Bottenfield",
                "Rene Gonzales"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2005",
            "words": [
                "Nate McLouth",
                "Juan Carlos Oviedo",
                "Omar Quintanilla",
                "Joe Saunders"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1907",
            "words": [
                "Neal Ball",
                "Bruno Block",
                "Tex Erwin",
                "Mike Mitchell"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2009",
            "words": [
                "Xavier Paul",
                "Aaron Poreda",
                "Ronald Belisario",
                "Kyle Blanks"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2014",
            "words": [
                "Andrew Susac",
                "Masahiro Tanaka",
                "Cam Bedrosian",
                "Ryan Buchter"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1940",
            "words": [
                "Jack Wallaesa",
                "Jesse Warren",
                "Ernie White",
                "Bill Baker"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1941",
            "words": [
                "Paul Erickson",
                "Tommy Hughes",
                "Jake Jones",
                "Oscar Judd"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1878",
            "words": [
                "The Only Nolan",
                "Phil Powers",
                "Bill Traffley",
                "Jim McCormick"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1950",
            "words": [
                "Danny O'Connell",
                "Hobie Landrith",
                "Jim Lemon",
                "Gus Bell"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cincinnati Reds: 50",
            "words": [
                "Jim Crowell",
                "Jonathan Broxton",
                "Guy Hoffman",
                "Scott Service"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cincinnati Reds: 44",
            "words": [
                "Brian Hunter",
                "Bob Malloy",
                "Eric Davis",
                "Mike Leake"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Montreal Expos: 25",
            "words": [
                "Troy O'Leary",
                "David Segui",
                "Gary Lucas",
                "Rex Hudler"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Florida Marlins: 8",
            "words": [
                "Preston Wilson",
                "Dallas McPherson",
                "Jim Eisenreich",
                "Álex González"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Francisco Giants",
            "words": [
                "John D'Acquisto",
                "Trevor Wilson",
                "Mickey Callaway",
                "Tom O'Malley"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Florida Marlins",
            "words": [
                "Mark Kotsay",
                "Brian Meadows",
                "Jake Elmore",
                "Steve Cishek"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Philadelphia Phillies",
            "words": [
                "Rick Leach",
                "Michael Jackson",
                "Roy Thomas",
                "Ricky Trlicek"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Mets",
            "words": [
                "Todd Hundley",
                "Joe Smith",
                "Travis Harper",
                "Lloyd McClendon"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Milwaukee Brewers",
            "words": [
                "Josh Hancock",
                "Craig Breslow",
                "Brandon Backe",
                "Yovani Gallardo"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Toronto Blue Jays",
            "words": [
                "Mike Henneman",
                "Tom Quinlan",
                "Pat Borders",
                "Vernon Wells"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago Cubs",
            "words": [
                "Dave Stevens",
                "Dave Geisel",
                "Shawon Dunston",
                "Blake Parker"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Pittsburgh Pirates",
            "words": [
                "Jim Brower",
                "Wes Chamberlain",
                "Jim Morrison",
                "Steve Nicosia"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Oakland Athletics",
            "words": [
                "Deolis Guerra",
                "Garry Hancock",
                "Shawn Hillegas",
                "Allan Lewis"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Brooklyn Dodgers",
            "words": [
                "Pat Ankenman",
                "Bobby Bragan",
                "Tex Carleton",
                "Johnny Peacock"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Diego Padres",
            "words": [
                "Duaner Sánchez",
                "Adam Warren",
                "Travis Wood",
                "Bill Bean"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Cardinals",
            "words": [
                "Brian Tallet",
                "Dave Wainhouse",
                "Bill Walker",
                "Preston Wilson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Francisco Giants: 18",
            "words": [
                "Billy Sorrell",
                "Craig Robinson",
                "Russ Davis",
                "Jay Canizaro"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Diego Padres: 24",
            "words": [
                "David Newhan",
                "Dave Wehrmeister",
                "Luis Salazar",
                "Robinson Canó"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Los Angeles Dodgers: 37",
            "words": [
                "Alex Taveras",
                "Ed Roebuck",
                "Glenn Hoffman",
                "Mike Baxter"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 29",
            "words": [
                "Harry Bright",
                "John Boccabella",
                "Byron Browne",
                "Scot Thompson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Chubby Dean",
                "Bob Kearney",
                "Roy Thomas",
                "Rich Morales"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Eddie Mulligan",
                "Bill McCall",
                "Travis Smith",
                "Bob Heffner"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Mike Munoz",
                "Turner Ward",
                "Sarge Connally",
                "Mickey McDermott"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 9 seasons",
            "words": [
                "Jim Turner",
                "Sal Butera",
                "Steve Sundra",
                "Larvell Blanks"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Joe Engel",
                "Lefty Holmes",
                "Tony Giuliani",
                "Ian Snell"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Joe Morgan",
                "Herb Moford",
                "Fred Fussell",
                "Fred Burchell"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 3 seasons",
            "words": [
                "Ralph Mellix",
                "Willard Mains",
                "Oscar Hutt",
                "Jesse Levan"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Frank Ellerbe",
                "Jute Bell",
                "Tom Sheehan",
                "Joe Lake"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Detroit Tigers",
            "words": [
                "Jack Morris",
                "Fernando Rodney",
                "Rip Sewell",
                "Virgil Trucks"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Oakland Athletics",
            "words": [
                "Chad Harville",
                "Bob Lacey",
                "Vin Mazzaro",
                "Dave Otto"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Giants",
            "words": [
                "Bill Terry",
                "Ozzie Virgil",
                "Bill White",
                "Sid Gordon"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cleveland Indians",
            "words": [
                "Harry Simpson",
                "Hal Trosky",
                "Kurt Bevacqua",
                "Lou Boudreau"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Tampa Bay Rays: 40",
            "words": [
                "Kevin Jepsen",
                "Wilson Ramos",
                "Roberto Hernández",
                "Érik Bédard"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Diego Padres: 44",
            "words": [
                "Rodrigo López",
                "Bob Owchinko",
                "Jake Peavy",
                "Eric Show"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Diego Padres: 41",
            "words": [
                "Sterling Hitchcock",
                "Aaron Harang",
                "Ron Roenicke",
                "Brian Falkenborg"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Diego Padres: 22",
            "words": [
                "Darrin Jackson",
                "Ruppert Jones",
                "Wally Joyner",
                "Marcus Giles"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Minnesota Twins",
            "words": [
                "Torii Hunter",
                "Corey Koskie",
                "Ken Schrom",
                "Adam Conley"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the St. Louis Cardinals",
            "words": [
                "Mike Moore",
                "Sam Freeman",
                "Mick Kelleher",
                "Luis Alicea"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago Cubs",
            "words": [
                "Kyle Lohse",
                "Taylor Teagarden",
                "Ryan Theriot",
                "Mark Huismann"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Texas Rangers",
            "words": [
                "Barry Manuel",
                "Robbie Ross",
                "Jody Reed",
                "Kevin Reimer"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1877",
            "words": [
                "Bobby Mitchell",
                "Tom Loftus",
                "George Miller",
                "Live Oak Taylor"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2004",
            "words": [
                "Scott Atchison",
                "Jeff Francis",
                "Jason Frasor",
                "Corey Hart"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2005",
            "words": [
                "Chris Denorfia",
                "Dan Johnson",
                "Shaun Marcum",
                "Skip Schumaker"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2002",
            "words": [
                "Jayson Werth",
                "Víctor Martínez",
                "Brandon Phillips",
                "Fernando Rodney"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Luis Leal",
                "Garry Hancock",
                "Ollie O'Mara",
                "Kevin Collins"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 11 seasons",
            "words": [
                "Neil Allen",
                "Roy Howell",
                "Bill Mueller",
                "Josh Billings"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Ken Jungels",
                "Charlie Mullen",
                "Dick Weik",
                "Rip Coleman"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Carroll Hardy",
                "Jim Weaver",
                "Larry Sheets",
                "Manny Parra"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Cincinnati Reds",
            "words": [
                "Eric Owens",
                "Bill Risley",
                "Tom Edens",
                "Paul Janish"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Milwaukee Brewers",
            "words": [
                "Lorenzo Cain",
                "Andrew Bailey",
                "Matt Morris",
                "Kip Wells"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Oakland Athletics",
            "words": [
                "Tyson Ross",
                "Tyler Yates",
                "Andrew Knapp",
                "T.J. Mathews"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago Cubs",
            "words": [
                "Randy Williams",
                "Bill Stoneman",
                "Sergio Mitre",
                "Jeff Beliveau"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Boston Red Sox",
            "words": [
                "Chuck Rainey",
                "Logan Schafer",
                "Chris Smith",
                "Trot Nixon"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Baltimore Orioles",
            "words": [
                "Joey Hamilton",
                "Bobby Grich",
                "Jerry Hairston",
                "Mike Mussina"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Mets",
            "words": [
                "Billy Beane",
                "Bill Pulsipher",
                "Daniel Murphy",
                "Kal Daniels"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Cincinnati Reds",
            "words": [
                "Andy Tracy",
                "Shane Halter",
                "Adam Rosales",
                "Chris Chambliss"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Bob Cerv",
                "Les Nunamaker",
                "Brickyard Kennedy",
                "Preacher Roe"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 16 seasons",
            "words": [
                "Bill Perkins",
                "Chad Kreuter",
                "Frank Snyder",
                "Dave Bancroft"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Ryan Bukvich",
                "Tracy Jones",
                "Don Black",
                "Charles Corbett"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Bart Johnson",
                "Adolfo Phillips",
                "Dennis Powell",
                "Evan Marshall"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 13 seasons",
            "words": [
                "Kevin Gregg",
                "Chris Young",
                "Ryan Madson",
                "Storm Davis"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "William Webster",
                "Ed Sicking",
                "Willie Hubert",
                "Del Bissonette"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Emil Verban",
                "Don Werner",
                "Justin Miller",
                "Jackie Brown"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 3 seasons",
            "words": [
                "Bill Eagan",
                "George Seward",
                "Jim Fridley",
                "Rudy Kallio"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the St. Louis Cardinals",
            "words": [
                "Marc Hill",
                "Bryn Smith",
                "Bret Barberie",
                "Jim Lindeman"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Pittsburgh Pirates",
            "words": [
                "Dennis Rasmussen",
                "Mark Johnson",
                "Tim Burke",
                "Ed Whitson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Cleveland Indians",
            "words": [
                "Burch Smith",
                "Ryan Garko",
                "Paul Shuey",
                "Joe Beckwith"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago White Sox",
            "words": [
                "Chuck Brinkman",
                "Bruce Bochy",
                "Robin Ventura",
                "Randy Velarde"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago Cubs",
            "words": [
                "Hugh Casey",
                "Frank Castillo",
                "Scott Downs",
                "Scott Fletcher"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Philadelphia Phillies",
            "words": [
                "Kevin Jordan",
                "Hal Lee",
                "Calvin Maduro",
                "Wendell Magee"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Oakland Athletics",
            "words": [
                "Scott Hemond",
                "A.J. Hinch",
                "Tyler Ladendorf",
                "Brian Lesher"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Montreal Expos",
            "words": [
                "Tim Wallach",
                "Warren Cromartie",
                "Gary Carter",
                "Scott Sanderson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Enos Slaughter",
                "Rogers Hornsby",
                "Stan Musial",
                "Steve Carlton"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Browns",
            "words": [
                "Willard Brown",
                "Tom Carey",
                "Harlond Clift",
                "Rip Collins"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Washington Nationals",
            "words": [
                "Ed Linke",
                "Jim Mallory",
                "Shairon Martis",
                "Luke Montz"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Oakland Athletics",
            "words": [
                "Tyson Ross",
                "Gary Woods",
                "Mark Bellhorn",
                "Scott Brosius"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Brooklyn Dodgers",
            "words": [
                "Gordon Slade",
                "Ed Stevens",
                "Dick Whitman",
                "Wayne Belardi"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cincinnati Reds",
            "words": [
                "Didi Gregorius",
                "Kip Gross",
                "Ryan Hanigan",
                "Tom Hume"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Montreal Expos",
            "words": [
                "Mike Lansing",
                "Charlie Lea",
                "Tony Armas",
                "Kent Bottenfield"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Toronto Blue Jays",
            "words": [
                "Brett Lawrie",
                "Luis Leal",
                "Aquilino López",
                "Darin Mastroianni"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Tito Landrum",
                "Jim Lindeman",
                "Héctor Luna",
                "Carlos Martínez"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Philadelphia Athletics",
            "words": [
                "Don Black",
                "Joe Boley",
                "Carl Doyle",
                "Jim Finigan"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Giants",
            "words": [
                "Blondy Ryan",
                "Pat Veltman",
                "Bill Voiselle",
                "Harry Danning"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Brooklyn Dodgers",
            "words": [
                "Harry Eisenstat",
                "Chico Fernández",
                "Charlie Gilbert",
                "Oris Hockett"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1976",
            "words": [
                "Brian Asselstine",
                "Doug Capilla",
                "Sam Mejías",
                "John Verhoeven"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1906",
            "words": [
                "Art Fromme",
                "Ed Holly",
                "Ed Lennox",
                "Ira Thomas"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1999",
            "words": [
                "David Cortés",
                "Wiki González",
                "Chad Harville",
                "Terrence Long"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2011",
            "words": [
                "Rubby De La Rosa",
                "Eric Fryer",
                "Johnny Giavotella",
                "Jeff Locke"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 11 seasons",
            "words": [
                "Pedro Feliz",
                "Theodore Breitenstein",
                "Al Bridwell",
                "Albie Lopez"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Ryan Flaherty",
                "George Smith",
                "Pokey Reese",
                "Greg McMichael"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 2 seasons",
            "words": [
                "Marty Shay",
                "Ace Williams",
                "Dean Thomas",
                "Bobby Coombs"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 18 seasons",
            "words": [
                "Mike Schmidt",
                "Tommy Corcoran",
                "Eddie Yost",
                "Brad Ausmus"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Cardinals",
            "words": [
                "Michael McKenry",
                "Whitey Moore",
                "Joe Nossek",
                "Ron Piché"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Oakland Athletics",
            "words": [
                "Marty Perez",
                "Milt Ramírez",
                "Brian Schlitter",
                "Chris Smith"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Philadelphia Phillies",
            "words": [
                "Shawn Camp",
                "Larry Christenson",
                "Bud Clancy",
                "Didi Gregorius"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Francisco Giants",
            "words": [
                "Vin Mazzaro",
                "Ramiro Peña",
                "Gene Richards",
                "Ryan Theriot"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cincinnati Reds: 64",
            "words": [
                "Jon Adkins",
                "Josh A. Smith",
                "Sun-Woo Kim",
                "Juan Francisco"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Houston Astros: 8",
            "words": [
                "Jed Lowrie",
                "Dave Adlesh",
                "Kevin Bass",
                "JB Shuck"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Houston Astros: 27",
            "words": [
                "Pete Harnisch",
                "John Cangelosi",
                "Frank LaCorte",
                "Dave Adlesh"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Houston Astros: 4",
            "words": [
                "Mike Brumley",
                "Karl Rhodes",
                "Pat Listach",
                "Jim Gentile"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1984",
            "words": [
                "Danny Tartabull",
                "Mickey Tettleton",
                "Rex Hudler",
                "Jimmy Key"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1995",
            "words": [
                "Doug Bochtler",
                "Jamie Brewington",
                "Jim Bruske",
                "Brad Clontz"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1962",
            "words": [
                "Bernie Allen",
                "Joe Pepitone",
                "Jim Hickman",
                "John Kennedy"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1931",
            "words": [
                "Paul Derringer",
                "Myril Hoag",
                "Mike Kreevich",
                "Gene Moore"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Los Angeles Dodgers",
            "words": [
                "Rick Rhoden",
                "Jerry Royster",
                "Henry Blanco",
                "Juan Castro"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Texas Rangers",
            "words": [
                "Dean Palmer",
                "Mike Stanley",
                "José Hernández",
                "Chad Kreuter"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Milwaukee Brewers",
            "words": [
                "Steve Sparks",
                "Bill Travers",
                "Wayne Twitchell",
                "Mike Adams"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cincinnati Reds",
            "words": [
                "C.J. Nitkowski",
                "Gary Nolan",
                "Joe Price",
                "Elmer Riddle"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Kansas City Athletics",
            "words": [
                "Allan Lewis",
                "Jim Nash",
                "Ron Stone",
                "Ron Tompkins"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Frank Fernández",
                "Bob Geren",
                "Nick Goody",
                "Ken Hunt"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Minnesota Twins",
            "words": [
                "Allan Anderson",
                "Randy Bass",
                "Nick Blackburn",
                "Rob Bowen"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cleveland Indians",
            "words": [
                "Gary Geiger",
                "Franklin Gutiérrez",
                "Steve Hargan",
                "Dave Hoskins"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Boston Red Sox: 64",
            "words": [
                "Jean Machi",
                "Will Middlebrooks",
                "Allen Webster",
                "Michael Bowden"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 43",
            "words": [
                "Michael Wuertz",
                "Newt Kimball",
                "Robert Gsellman",
                "Wes Covington"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 28",
            "words": [
                "Steve Mesner",
                "Kal Daniels",
                "Marty Keough",
                "Mitch Williams"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Minnesota Twins: 44",
            "words": [
                "Jim Roland",
                "Chuck James",
                "Rob Bowen",
                "Ken Landreaux"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the California Angels",
            "words": [
                "Tim Wallach",
                "Chris Gomez",
                "Mark Holzemer",
                "Ron Jackson"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Texas Rangers",
            "words": [
                "Ed Lynch",
                "Roger Pavlik",
                "Mark Little",
                "Kevin Mench"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Montreal Expos",
            "words": [
                "Mark McGwire",
                "Charles Johnson",
                "F.P. Santangelo",
                "Henry Mateo"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Toronto Blue Jays",
            "words": [
                "Woody Williams",
                "Geno Petralli",
                "Doug Linton",
                "Tim Crabtree"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Pittsburgh Pirates",
            "words": [
                "Steve Parris",
                "Rick Renteria",
                "Bill Schuster",
                "Rod Scurry"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Detroit Tigers",
            "words": [
                "Gee Walker",
                "Hub Walker",
                "Rudy York",
                "Hoot Evers"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for San Diego Padres",
            "words": [
                "Rich Rodriguez",
                "Ron Tingley",
                "Tim Worrell",
                "Bill Almon"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Milt Ramírez",
                "Colby Rasmus",
                "Chris Richard",
                "Shane Robinson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1981",
            "words": [
                "Brian Giles",
                "Wallace Johnson",
                "Rick Leach",
                "Tom Niedenfuer"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1974",
            "words": [
                "Ken Macha",
                "Ed Ott",
                "Jim Otten",
                "Stan Papi"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1971",
            "words": [
                "Chris Arnold",
                "Bill Greif",
                "Marv Lane",
                "Lee Richard"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1920",
            "words": [
                "Mack Eggleston",
                "Luther Farrell",
                "Dave Malarcher",
                "Clyde Manion"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Detroit Tigers",
            "words": [
                "Francis Beltrán",
                "Lou Berberet",
                "Milt Bolling",
                "Don Buddin"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Miami Marlins",
            "words": [
                "Martín Prado",
                "Rafael Furcal",
                "Brad Penny",
                "Curtis Granderson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Seattle Mariners",
            "words": [
                "Joe Decker",
                "Sean Doolittle",
                "Larry Milbourne",
                "John Moses"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Baltimore Orioles",
            "words": [
                "Curt Motton",
                "Ronny Paulino",
                "Floyd Rayford",
                "Tike Redman"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1976",
            "words": [
                "Mike Krukow",
                "Lee Mazzilli",
                "Joel Youngblood",
                "Doug Bair"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1872",
            "words": [
                "Candy Nelson",
                "Jack Burdock",
                "Paul Hines",
                "Jim O'Rourke"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1967",
            "words": [
                "Hal King",
                "Rich Morales",
                "Curt Motton",
                "Bill Stoneman"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1965",
            "words": [
                "Dick Selma",
                "Luke Walker",
                "Glenn Beckert",
                "Tom Egan"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Minnesota Twins: 53",
            "words": [
                "Mark Guthrie",
                "Nick Blackburn",
                "David Hale",
                "Joe Mays"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Oakland Athletics: 12",
            "words": [
                "D'Angelo Jimenez",
                "Mickey Klutts",
                "David DeJesus",
                "Esteban Germán"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Diego Padres: 45",
            "words": [
                "Vicente Romo",
                "Doug Bochtler",
                "Marc Rzepczynski",
                "Justin Hampson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Detroit Tigers: 45",
            "words": [
                "Dave Borkowski",
                "Doug Bochtler",
                "John Wockenfuss",
                "Dave Tobik"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Toronto Blue Jays",
            "words": [
                "Sean Green",
                "Steve Karsay",
                "Reed Johnson",
                "Billy Traber"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago White Sox",
            "words": [
                "John Montague",
                "Kevan Smith",
                "Clayton Richard",
                "Willie McGee"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Boston Red Sox",
            "words": [
                "Cory Bailey",
                "Lou Merloni",
                "Tim Blackwell",
                "Jason Castro"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Oakland Athletics",
            "words": [
                "Tim Hudson",
                "Ryan Ludwick",
                "Brad Ziegler",
                "Chet Lemon"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cleveland Indians: 41",
            "words": [
                "Felix Mackiewicz",
                "Art Houtteman",
                "Charles Nagy",
                "Mike Hedlund"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Francisco Giants: 31",
            "words": [
                "J.R. Phillips",
                "Larry Herndon",
                "Chris Arnold",
                "Robb Nen"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Francisco Giants: 17",
            "words": [
                "Bill White",
                "Kevin Bass",
                "Mackey Sasser",
                "Chris Arnold"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Francisco Giants: 15",
            "words": [
                "Greg Litton",
                "Doug Mirabelli",
                "Chris Arnold",
                "Bob Brenly"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1928",
            "words": [
                "Gus Mancuso",
                "Johnny Moore",
                "Clay Touchstone",
                "Bill Dickey"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1920",
            "words": [
                "Clarence Huber",
                "Joe Kiefer",
                "Bobby LaMotte",
                "Dud Lee"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1902",
            "words": [
                "Harry Bemis",
                "Tom Jones",
                "Addie Joss",
                "Andy Coakley"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1970",
            "words": [
                "Mickey Rivers",
                "Ken Singleton",
                "Larry Bowa",
                "Jeff Burroughs"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cincinnati Reds: 45",
            "words": [
                "Jake Wood",
                "Jimmy Anderson",
                "Keith Hughes",
                "Wayne Simpson"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 49",
            "words": [
                "Jake Arrieta",
                "Jimmy Anderson",
                "Frank Secory",
                "Félix Heredia"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Boston Red Sox: 56",
            "words": [
                "Hansel Robles",
                "Iván De Jesús",
                "Franklin Morales",
                "Darren Bragg"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Milwaukee Brewers: 7",
            "words": [
                "Eric Young Sr.",
                "Frank Tepedino",
                "Mark Reynolds",
                "Danny Walton"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cincinnati Reds: 48",
            "words": [
                "Gabe White",
                "Ted Power",
                "Rick Reed",
                "Jerry Spradlin"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Brooklyn Dodgers: 14",
            "words": [
                "George Kelly",
                "Gil Hodges",
                "Dick Whitman",
                "Freddie Fitzsimmons"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 17",
            "words": [
                "Jess Dobernic",
                "Matt Garza",
                "Frank Coggins",
                "Félix Pie"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 35",
            "words": [
                "Hy Vandenberg",
                "Geoff Zahn",
                "Randall Simon",
                "Hiram Bithorn"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Colorado Rockies",
            "words": [
                "Kirt Manwaring",
                "Daniel Murphy",
                "Denny Neagle",
                "Roy Oswalt"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Cardinals",
            "words": [
                "Rich Gedman",
                "Jim Hickman",
                "Tim Hulett",
                "Jim Konstanty"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Detroit Tigers",
            "words": [
                "Wally Schang",
                "Lou Whitaker",
                "Doc Cramer",
                "Cy Perkins"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Minnesota Twins",
            "words": [
                "Torii Hunter",
                "Paul Molitor",
                "Rubén Sierra",
                "Joe Niekro"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 14 seasons",
            "words": [
                "Andy Benes",
                "Riggs Stephenson",
                "Todd Stottlemyre",
                "Freddie Patek"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 9 seasons",
            "words": [
                "Ed Swartwood",
                "Bobby Del Greco",
                "Roy Welmaker",
                "Tom Carey"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 16 seasons",
            "words": [
                "Bill Nicholson",
                "Bill Robinson",
                "Willis Hudlin",
                "Malachi Kittridge"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Charlie Hollocher",
                "Mike Koplove",
                "Clarence Lamar",
                "Roger Pavlik"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Yankees",
            "words": [
                "Spud Chandler",
                "Kyle Davies",
                "Joe DeMaestri",
                "Jacoby Ellsbury"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Philadelphia Phillies",
            "words": [
                "Bill Nicholson",
                "Dan Plesac",
                "Bill Robinson",
                "Mike Schmidt"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago White Sox",
            "words": [
                "Smoky Burgess",
                "Dixie Howell",
                "Michael Jackson",
                "Claude Osteen"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Colorado Rockies",
            "words": [
                "Walt McKeel",
                "J.R. Phillips",
                "Paul Phillips",
                "Harvey Pulliam"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Karl Drews",
                "Monk Dubiel",
                "Cedric Durst",
                "Andy Fox"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Pittsburgh Pirates",
            "words": [
                "Steve Nicosia",
                "Red Nonnenkamp",
                "Tommy O'Brien",
                "Ed Ott"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Montreal Expos",
            "words": [
                "Andrés Galarraga",
                "Matt Stairs",
                "Andre Dawson",
                "Randy Johnson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Houston Colt .45s",
            "words": [
                "Ivan Murrell",
                "Walt Williams",
                "Larry Dierker",
                "Joe Hoerner"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 14 seasons",
            "words": [
                "Howard Johnson",
                "Harry Williams",
                "Woodie Held",
                "Terry Steinbach"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 13 seasons",
            "words": [
                "Joe DiMaggio",
                "Brett Anderson",
                "Clint Thomas",
                "Shawn Estes"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Jim Constable",
                "Max Moroff",
                "Junior Walsh",
                "Steve Luebber"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Eric Rasmussen",
                "Jake Petricka",
                "Cliff Lee",
                "Arnold Earley"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1921",
            "words": [
                "Johnny Mokan",
                "Charlie Hancock",
                "Lemuel Hawkins",
                "George Toporcer"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1981",
            "words": [
                "Albert Hall",
                "Brad Havens",
                "Tim Laudner",
                "Charlie Puleo"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1884",
            "words": [
                "Sandy Griffin",
                "Joe Gunson",
                "Henry Larkin",
                "John Milligan"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1943",
            "words": [
                "Hal Peck",
                "Ed Sauer",
                "Pat Seerey",
                "Ken Trinkle"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Billy Urbanski",
                "George Kottaras",
                "Art Mahaffey",
                "Doug Linton"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Tom Hilgendorf",
                "Bobby Jones",
                "Bill Hunnefield",
                "Jack Sutthoff"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "Jamie Walker",
                "Ryan Doumit",
                "Eddie Watt",
                "Otto Hess"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Johnny Hayes",
                "Flint Rhem",
                "Johan Santana",
                "John Wetteland"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Detroit Tigers",
            "words": [
                "Mark Redman",
                "Travis Fryman",
                "Corey Knebel",
                "Keith Foulke"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Oakland Athletics",
            "words": [
                "Brian Dorsett",
                "Greg Cadaret",
                "Brian Lesher",
                "Robert Fick"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Mets",
            "words": [
                "Scott Erickson",
                "Roger McDowell",
                "Tim Foli",
                "Scott Servais"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Kansas City Royals",
            "words": [
                "Kevin Appier",
                "Cody Reed",
                "Jimmy Gobble",
                "Jeremy Affeldt"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1981",
            "words": [
                "Kevin Hickey",
                "Mike Marshall",
                "Andy McGaffigan",
                "John Shelby"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1991",
            "words": [
                "Mark Petkovsek",
                "Bob Scanlan",
                "Pete Schourek",
                "Scott Servais"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1919",
            "words": [
                "Bill Hubbell",
                "Dickey Kerr",
                "Snooks Dowd",
                "Wally Kimmick"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1990",
            "words": [
                "Jeff Conine",
                "Moisés Alou",
                "Luis Gonzalez",
                "Frank Thomas"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Phillies: 39",
            "words": [
                "Lou Novikoff",
                "Donn Pall",
                "Lerrin LaGrow",
                "Paul Brown"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Diego Padres: 28",
            "words": [
                "Doug Mirabelli",
                "Ruppert Jones",
                "Randy Myers",
                "Lou Merloni"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "California Angels: 16",
            "words": [
                "Dave LaRoche",
                "John Curtis",
                "Ken Berry",
                "Ron Jackson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago White Sox: 45",
            "words": [
                "Gregory Infante",
                "Dave Righetti",
                "Shawn Abner",
                "Stan Bahnsen"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Boston Red Sox",
            "words": [
                "Ed Sprague",
                "Justin Duchscherer",
                "Skip Jutze",
                "Marc Sullivan"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Yankees",
            "words": [
                "Jason Grilli",
                "Don Mattingly",
                "Corban Joseph",
                "Phil Hughes"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Texas Rangers",
            "words": [
                "Pete O'Brien",
                "Al Holland",
                "Charlie O'Brien",
                "Craig Gentry"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Atlanta Braves",
            "words": [
                "Quintin Berry",
                "Jamie Easterly",
                "Biff Pocoroba",
                "Steve Finley"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago White Sox",
            "words": [
                "Alex Fernandez",
                "Frank Grube",
                "Butch Henline",
                "Mike Hershberger"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Los Angeles Angels of Anaheim",
            "words": [
                "Andrew Romine",
                "Jered Weaver",
                "Bobby Wilson",
                "Sean Rodríguez"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Manny Aybar",
                "Johnny Beazley",
                "Les Bell",
                "Alan Benes"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for San Francisco Giants",
            "words": [
                "Jerome Williams",
                "Matty Alou",
                "Rich Aurilia",
                "Will Clark"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Baltimore Orioles: 35",
            "words": [
                "Daniel Cabrera",
                "Del Rice",
                "Brad Brach",
                "Rick Helling"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cleveland Indians: 36",
            "words": [
                "Sam Dente",
                "Preston Guilmet",
                "Brad Arnsberg",
                "Hal Peck"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Toronto Blue Jays: 9",
            "words": [
                "John Olerud",
                "Rick Leach",
                "John Mayberry",
                "Gregg Zaun"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Texas Rangers: 7",
            "words": [
                "Eric Young Sr.",
                "Orlando Mercado",
                "David Murphy",
                "Bud Harrelson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "John Rocker",
                "Allyn Stout",
                "Ken Williams",
                "Carl Warwick"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 14 seasons",
            "words": [
                "Andy Hassler",
                "Troy Percival",
                "Howie Shanks",
                "Matt Joyce"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Jimmy Sebring",
                "Earl Blackburn",
                "Darrel Akerfelds",
                "Curt Roberts"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Bill Merritt",
                "Jim Holdsworth",
                "Charlie Williams",
                "Duane Pillette"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Mets",
            "words": [
                "Mark Grudzielanek",
                "Zack Godley",
                "Tim Leary",
                "Mike Scott"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Anaheim Angels",
            "words": [
                "Kevin Jepsen",
                "John Lackey",
                "Steve Delabar",
                "Mark Trumbo"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Diego Padres",
            "words": [
                "Harold Reynolds",
                "Matt Clement",
                "Lance Painter",
                "Joe Ross"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Colorado Rockies",
            "words": [
                "Ryan Spilborghs",
                "Quinton McCracken",
                "Juan Acevedo",
                "Tyler Matzek"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "St. Louis Cardinals: 16",
            "words": [
                "Ray Jablonski",
                "Jesse Haines",
                "Scott Rolen",
                "J.C. Romero"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago White Sox: 39",
            "words": [
                "Chris Getz",
                "Ed Klieman",
                "Aaron Miles",
                "Bob Priddy"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Oakland Athletics: 45",
            "words": [
                "Danny Tartabull",
                "Jay Witasick",
                "Jim Johnson",
                "Jim Mecir"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Pittsburgh Pirates: 55",
            "words": [
                "Michael McKenry",
                "Dave Hostetler",
                "Jimmy Anderson",
                "Matt Capps"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 9 seasons",
            "words": [
                "Reid Brignac",
                "Socks Seibold",
                "David Aardsma",
                "Bill Force"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Robinson Cancel",
                "Joe Ellick",
                "Don Richmond",
                "Herb Hunter"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "Chris Stynes",
                "Dan Wilson",
                "Jimmy Outlaw",
                "Roy Lee Jackson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 17 seasons",
            "words": [
                "Bob Bailey",
                "Kenny Lofton",
                "Chan Ho Park",
                "Lonnie Smith"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Steve Frey",
                "Jimmy Brown",
                "Rafael Landestoy",
                "Joe Magrane"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Art Allison",
                "King Brady",
                "Tyler Moore",
                "Harry McCormick"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "Tim Stauffer",
                "Matty McIntyre",
                "Greg Brock",
                "Huck Betts"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Jim Adduci",
                "Roxey Roach",
                "Dick Lanahan",
                "Kyle Lobstein"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Detroit Tigers: 21",
            "words": [
                "Sean Casey",
                "Scott Livingstone",
                "Joe Sparma",
                "Willie Hernández"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Detroit Tigers: 39",
            "words": [
                "Earl Harrist",
                "Jarrod Saltalamacchia",
                "Neftalí Feliz",
                "Ramón Santiago"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Detroit Tigers: 18",
            "words": [
                "Johnny Damon",
                "Bob Melvin",
                "Gary Pettis",
                "Tom Morgan"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cincinnati Reds: 23",
            "words": [
                "Adam Rosales",
                "Yonder Alonso",
                "Harry Craft",
                "Lloyd McClendon"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1871",
            "words": [
                "George Zettlein",
                "Bob Addy",
                "John Bass",
                "Bill Craver"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1943",
            "words": [
                "Johnny Ostrowski",
                "Nap Reyes",
                "Milo Candini",
                "Buddy Kerr"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1991",
            "words": [
                "Mark Wohlers",
                "Denny Neagle",
                "Mo Vaughn",
                "Andy Ashby"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1980",
            "words": [
                "Mark Brouhard",
                "Marty Bystrom",
                "Joe DeSa",
                "Jim Dorsey"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Minnesota Twins: 27",
            "words": [
                "Jerry Koosman",
                "Willie Eyre",
                "Mike Fornieles",
                "Chuck Stobbs"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Detroit Tigers: 31",
            "words": [
                "Eric Munson",
                "Larry Herndon",
                "Bob Cain",
                "Paul Calvert"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Oakland Athletics: 36",
            "words": [
                "Mike Fetters",
                "Derek Norris",
                "Tim Worrell",
                "Tito Francona"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "St. Louis Cardinals: 41",
            "words": [
                "Ted Sizemore",
                "Joe Magrane",
                "Mitchell Boggs",
                "Todd Burns"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 22",
            "words": [
                "John Boccabella",
                "Matt Garza",
                "Billy Hatcher",
                "Jerry Mumphrey"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Brooklyn Dodgers: 27",
            "words": [
                "Pete Reiser",
                "Fritz Ostermueller",
                "Tony Giuliani",
                "Dutch Leonard"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Washington Nationals: ",
            "words": [
                "René Monteagudo",
                "Harley Boss",
                "Monte Weaver",
                "Morrie Aderholt"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cleveland Indians: 7",
            "words": [
                "Bubba Phillips",
                "Miguel de la Hoz",
                "Al Rosen",
                "Ryan Flaherty"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1965",
            "words": [
                "Curt Blefary",
                "Byron Browne",
                "Mike Hedlund",
                "Larry Jaster"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1974",
            "words": [
                "Aurelio López",
                "Bill Almon",
                "Larry Herndon",
                "Ray Knight"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1948",
            "words": [
                "Johnny Bucha",
                "Paul Burris",
                "Cliff Chambers",
                "Woody Main"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1969",
            "words": [
                "Tom Bradley",
                "Gary Gentry",
                "Billy Grabarkewitz",
                "Don Hahn"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Cleveland Indians",
            "words": [
                "Dennis Eckersley",
                "Larry Johnson",
                "Chuck Jackson",
                "Russell Branyan"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Oakland Athletics",
            "words": [
                "Todd Van Poppel",
                "Wayne Gross",
                "Mike Davis",
                "Curt Young"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Montreal Expos",
            "words": [
                "Shane Andrews",
                "Jeff Parrett",
                "Randy O'Neal",
                "Barry Foote"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago Cubs",
            "words": [
                "Ricky Nolasco",
                "Joe Carter",
                "Ryan Flaherty",
                "Jerry Blevins"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Montreal Expos: 33",
            "words": [
                "Carlos Pérez",
                "Wayne Twitchell",
                "Larry Walker",
                "Brad Gulden"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Montreal Expos: 11",
            "words": [
                "Frank Taveras",
                "Shane Andrews",
                "Wayne Garrett",
                "Spike Owen"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Washington Nationals: 57",
            "words": [
                "Jason Bergmann",
                "Tyler Moore",
                "Nate Karns",
                "Chad Gaudin"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Detroit Tigers: 52",
            "words": [
                "Quintin Berry",
                "John Farrell",
                "Matt Sinatro",
                "José Bautista"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 15 seasons",
            "words": [
                "Jason Grilli",
                "Lou Finney",
                "Jack Fournier",
                "Santiago Casilla"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "Joe Crede",
                "Freddy Galvis",
                "John Jaha",
                "Bobby Valentine"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 14 seasons",
            "words": [
                "Francisco Cordero",
                "Firpo Marberry",
                "Roberto Kelly",
                "Tim Worrell"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Hiram Bithorn",
                "George Nash",
                "Cot Deal",
                "Matt Riley"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Seattle Mariners",
            "words": [
                "Ichiro Suzuki",
                "Rick Honeycutt",
                "Ken Griffey Jr.",
                "Alex Rodriguez"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Johnny Bucha",
                "Doug Capilla",
                "Allen Craig",
                "Pat Crawford"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Giants",
            "words": [
                "Al Glossop",
                "Sheldon Jones",
                "Ray Katt",
                "Monty Kennedy"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cincinnati Reds",
            "words": [
                "John Riedling",
                "Josh Roenicke",
                "Les Scarsella",
                "Eddie Shokes"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Philadelphia Phillies",
            "words": [
                "Gus Niarhos",
                "Kent Peterson",
                "Mel Roach",
                "Bama Rowell"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago White Sox",
            "words": [
                "Ron Reed",
                "Red Faber",
                "Muddy Ruel",
                "Dave Stieb"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Yankees",
            "words": [
                "Chris Capuano",
                "Rocky Colavito",
                "John Flaherty",
                "Brett Gardner"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Browns",
            "words": [
                "Heinie Mueller",
                "Dizzy Dean",
                "Fred Johnson",
                "Pete Appleton"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Pittsburgh Pirates",
            "words": [
                "John Milner",
                "Matt Morris",
                "Omar Olivares",
                "Babe Phelps"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Mets",
            "words": [
                "Kelly Johnson",
                "Ramón Martínez",
                "Gary Matthews",
                "Félix Millán"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cleveland Indians",
            "words": [
                "Fabian Gaffke",
                "Brett Hayes",
                "Tony Horton",
                "José Jiménez"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Atlanta Braves",
            "words": [
                "Rod Gilbreath",
                "Guillermo Heredia",
                "John Mizerock",
                "Darryl Motley"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Houston Astros",
            "words": [
                "Mark Knudson",
                "Wade Miller",
                "Randy Niemann",
                "Luis Pujols"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Boston Red Sox",
            "words": [
                "Ryan Kalish",
                "George Kottaras",
                "Bill LeFebvre",
                "Lou Legett"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Colorado Rockies",
            "words": [
                "Mark Thompson",
                "Tony Wolters",
                "Garrett Atkins",
                "Doug Bernier"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Los Angeles Dodgers",
            "words": [
                "James Loney",
                "Mike Marshall",
                "Antonio Osuna",
                "Jim Poole"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Montreal Expos",
            "words": [
                "Orlando Mercado",
                "Bobby Muñoz",
                "John O'Donoghue",
                "Gil Reyes"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Yankees",
            "words": [
                "Rick Bladt",
                "Juan Bonilla",
                "Duke Carmel",
                "Ike Davis"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Diego Padres",
            "words": [
                "Dane Iorg",
                "Bobby Jones",
                "Ted Kubiak",
                "Keith Lockhart"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Arizona Diamondbacks",
            "words": [
                "Bob Wickman",
                "Bobby Witt",
                "Roberto Alomar",
                "Eric Chavez"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Oakland Athletics: 58",
            "words": [
                "Evan Scribner",
                "Joe Klink",
                "Adrian Garrett",
                "Mike Mohler"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cleveland Indians: 47",
            "words": [
                "Scott Sauerbeck",
                "Paul Calvert",
                "Shane Spencer",
                "Shelley Duncan"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Texas Rangers: 24",
            "words": [
                "Ed Figueroa",
                "Gary Pettis",
                "Mike Devereaux",
                "Steve Kemp"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Phillies: 35",
            "words": [
                "Jim Konstanty",
                "Kelly Stinnett",
                "Granny Hamner",
                "Bobby Shantz"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2002",
            "words": [
                "Tyler Walker",
                "Alfredo Amézaga",
                "Josh Bard",
                "Esteban Germán"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2004",
            "words": [
                "Curtis Granderson",
                "Scott Kazmir",
                "René Rivera",
                "Yadier Molina"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1921",
            "words": [
                "Ray Kolp",
                "Roy Roberts",
                "Riggs Stephenson",
                "Pepper Daniels"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1988",
            "words": [
                "Bob Geren",
                "Doug Jennings",
                "Tim Jones",
                "Carlos Quintana"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Houston Astros",
            "words": [
                "Pete Ladd",
                "Lance McCullers Jr.",
                "James Mouton",
                "Troy Patton"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Baltimore Orioles",
            "words": [
                "Nerio Rodríguez",
                "Miguel Socolovich",
                "Jack Voigt",
                "Mike Wright Jr."
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago White Sox",
            "words": [
                "Norm Schlueter",
                "Chris Singleton",
                "Francisco Barrios",
                "Jason Bourgeois"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Ken Hill",
                "Marc Hill",
                "Johnny Hopp",
                "Larry Jackson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1992",
            "words": [
                "John Valentin",
                "Pedro Borbón",
                "Doug Linton",
                "Pat Mahomes"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1935",
            "words": [
                "Johnny Taylor",
                "Hy Vandenberg",
                "Mace Brown",
                "Tom Carey"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1952",
            "words": [
                "Hersh Freeman",
                "Jim Fridley",
                "Ron Negray",
                "Jerry Snyder"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1959",
            "words": [
                "Bob Duliba",
                "Jack Kralick",
                "Bob Saverine",
                "Wes Stock"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Francisco Giants",
            "words": [
                "Joe Panik",
                "Eric Gunderson",
                "Jerome Williams",
                "Reggie Williams"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Toronto Blue Jays",
            "words": [
                "Cris Carpenter",
                "Brad Mills",
                "Willie Blair",
                "Orlando Hudson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Los Angeles Dodgers",
            "words": [
                "Chris Nichting",
                "Benny Distefano",
                "David Price",
                "Jim Parque"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the St. Louis Cardinals",
            "words": [
                "Ed Vosberg",
                "Rick Aguilera",
                "Cliff Politte",
                "Daric Barton"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Boston Red Sox",
            "words": [
                "Will Middlebrooks",
                "Dave Magadan",
                "David Eckstein",
                "Pat Burrell"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Kansas City Royals",
            "words": [
                "Matt Treanor",
                "Ruppert Jones",
                "Bobby Castillo",
                "Tom Poquette"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Texas Rangers",
            "words": [
                "Drew Pomeranz",
                "Gary Gray",
                "Darren Oliver",
                "Kenny Rogers"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago White Sox",
            "words": [
                "Mike Lowell",
                "Mark Johnson",
                "Jake Petricka",
                "Frank Thomas"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Philadelphia Phillies",
            "words": [
                "Andy Seminick",
                "Chris Short",
                "Larry Bowa",
                "Bubber Jonnard"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Milwaukee Braves",
            "words": [
                "Taylor Phillips",
                "Bob Roselli",
                "Chuck Tanner",
                "Frank Torre"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Houston Astros",
            "words": [
                "Norm Miller",
                "Bud Norris",
                "Jim Ray",
                "J.R. Richard"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Philadelphia Athletics",
            "words": [
                "Joe Taylor",
                "Dib Williams",
                "Babe Barna",
                "Herman Besse"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1933",
            "words": [
                "Hank Leiber",
                "Bobby Coombs",
                "Roy Williams",
                "Rainey Bibbs"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1912",
            "words": [
                "Wheezer Dell",
                "Ellis Johnson",
                "Duke Kenworthy",
                "Jimmy Lavender"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1978",
            "words": [
                "Shane Rawley",
                "Steve Trout",
                "Larry McWilliams",
                "Paul Mirabella"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1945",
            "words": [
                "Ray Noble",
                "Johnny Hetki",
                "Don Lund",
                "Al Gettel"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 3 seasons",
            "words": [
                "Matt Watson",
                "Bill Harris",
                "Harry Ables",
                "Roy Joiner"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 11 seasons",
            "words": [
                "David Riske",
                "Eddie Waitkus",
                "Dude Esterbrook",
                "Thomas Howard"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 14 seasons",
            "words": [
                "Willie Gisentaner",
                "Russell Martin",
                "Hobie Landrith",
                "Mike Easler"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Ed Ott",
                "Dave Nilsson",
                "Nino Espinosa",
                "Chris Turner"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 18 seasons",
            "words": [
                "Murry Dickson",
                "Charlie Jamieson",
                "Jack Morris",
                "Mike Mussina"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 17 seasons",
            "words": [
                "Roberto Alomar",
                "Oscar Gamble",
                "Moe Drabowsky",
                "Mike Jorgensen"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Matt Anderson",
                "Marv Goodwin",
                "Hy Vandenberg",
                "Tom Nieto"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 11 seasons",
            "words": [
                "Curt Leskanic",
                "Hawk Taylor",
                "Bill Hall",
                "Steve Farr"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Philadelphia Athletics",
            "words": [
                "Doc Cramer",
                "Jimmie Foxx",
                "Al Simmons",
                "Jimmy Dykes"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cleveland Indians",
            "words": [
                "Jeff Manto",
                "Al Milnar",
                "Dale Mitchell",
                "Dave Mlicki"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Detroit Tigers",
            "words": [
                "Steve Baker",
                "Kimera Bartee",
                "Quintin Berry",
                "Brennan Boesch"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Minnesota Twins",
            "words": [
                "Greg Gagne",
                "Mark Guthrie",
                "Pat Kelly",
                "Francisco Liriano"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1957",
            "words": [
                "Barry Latman",
                "Andre Rodgers",
                "Bob Shaw",
                "Lenny Green"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1953",
            "words": [
                "Billy Hunter",
                "Ted Kazanski",
                "Bob Keegan",
                "Eddie O'Brien"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2002",
            "words": [
                "Bill Hall",
                "Orlando Hudson",
                "Brad Lidge",
                "Shane Loux"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1996",
            "words": [
                "Brian Banks",
                "Robert Ellis",
                "Tyler Houston",
                "José Paniagua"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Washington Nationals",
            "words": [
                "Joe Tipton",
                "Gil Torres",
                "Max Wilson",
                "Jimmie DeShong"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Cardinals",
            "words": [
                "Les Bell",
                "Aaron Brooks",
                "Ron Bryant",
                "Verne Clemons"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Los Angeles Dodgers",
            "words": [
                "Todd Coffey",
                "Lance Cormier",
                "Ed Goodson",
                "Enzo Hernández"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Kansas City Athletics",
            "words": [
                "Ed Blake",
                "Ted Bowsfield",
                "Cloyd Boyer",
                "Moe Burtschy"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 41",
            "words": [
                "Carroll Yerkes",
                "Warren Brusstar",
                "Fred Norman",
                "Adrian Sampson"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Kansas City Royals: 1",
            "words": [
                "Denny Hocking",
                "Buddy Biancalana",
                "Luis Ordaz",
                "Cookie Rojas"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Baltimore Orioles: 3",
            "words": [
                "Ryan Flaherty",
                "Clay Dalrymple",
                "Ron Hansen",
                "George Kell"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Colorado Rockies: 34",
            "words": [
                "Aaron Laffey",
                "Matt Herges",
                "Matt Belisle",
                "Larry Bigbie"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Washington Nationals",
            "words": [
                "A.J. Cole",
                "Joel Hanrahan",
                "Dick Hyde",
                "Nate Karns"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for San Francisco Giants",
            "words": [
                "Mackey Sasser",
                "Hunter Strickland",
                "Gary Thomasson",
                "Brian Wilson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Detroit Tigers",
            "words": [
                "Elden Auker",
                "Reno Bertoia",
                "Dave Borkowski",
                "Rico Brogna"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Seattle Mariners",
            "words": [
                "Adam Moore",
                "Darren Bragg",
                "Greg Dobbs",
                "Erik Hanson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Minnesota Twins",
            "words": [
                "Kevin Slowey",
                "Pete Falcone",
                "Steve Brye",
                "Tony Fossas"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Florida Marlins",
            "words": [
                "Nick Hundley",
                "Matt Dominguez",
                "Jim Adduci",
                "Randy Winn"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Los Angeles Dodgers",
            "words": [
                "Brad Gulden",
                "Randy Wolf",
                "Dave Stewart",
                "Scott Elbert"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Detroit Tigers",
            "words": [
                "Tom Brookens",
                "Bill Laskey",
                "Alan Trammell",
                "Alex Avila"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "St. Louis Browns: 22",
            "words": [
                "Earl McNeely",
                "Lou Kretlow",
                "Glenn Liebhardt",
                "Jim Walkup"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "St. Louis Browns: 15",
            "words": [
                "Hal Epps",
                "Roy Sievers",
                "Joe Grace",
                "Debs Garms"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago White Sox: 28",
            "words": [
                "D'Angelo Jimenez",
                "Cass Michaels",
                "Esteban Beltré",
                "Jack Hallett"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Los Angeles Angels of Anaheim: 22",
            "words": [
                "Kirk Nieuwenhuis",
                "Conor Gillaspie",
                "Cory Aldridge",
                "Trevor Bell"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1946",
            "words": [
                "Al Dark",
                "Carl Furillo",
                "Johnny Groth",
                "Grady Hatton"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1871",
            "words": [
                "Dave Eggler",
                "Tom York",
                "Davy Force",
                "Joe Quest"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1991",
            "words": [
                "Warren Newson",
                "Rico Rossy",
                "Archie Corbin",
                "David Howard"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2001",
            "words": [
                "Carlos Zambrano",
                "Wilson Betemit",
                "Brandon Inge",
                "César Izturis"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Colorado Rockies: 41",
            "words": [
                "Wil Nieves",
                "Mitchell Boggs",
                "Mark Knudson",
                "Denny Stark"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Houston Astros: 19",
            "words": [
                "Brian Bogusevic",
                "Howie Reed",
                "Brian Hunter",
                "Derrel Thomas"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 47",
            "words": [
                "Miguel Montero",
                "Donn Pall",
                "Frank Fernández",
                "Bob Shaw"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Phillies: 17",
            "words": [
                "Ozzie Virgil",
                "Ed Roebuck",
                "Scott Rolen",
                "Reid Brignac"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1914",
            "words": [
                "Johnny Rawlings",
                "Hank Schreiber",
                "Everett Scott",
                "Allen Sothoron"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1943",
            "words": [
                "Al Brazle",
                "George Metkovich",
                "Cass Michaels",
                "Carl Scheib"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1982",
            "words": [
                "Ron Kittle",
                "Doug Sisk",
                "Paul Zuvella",
                "Jay Baller"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1990",
            "words": [
                "Omar Olivares",
                "Scott Radinsky",
                "Jeff Shaw",
                "Turner Ward"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Atlanta Braves: 14",
            "words": [
                "Marv Staehle",
                "Martín Prado",
                "Ramiro Peña",
                "Andrés Galarraga"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Texas Rangers: 34",
            "words": [
                "Jim Roland",
                "Steve Wilson",
                "Jim Kern",
                "Paul Mirabella"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Texas Rangers: 25",
            "words": [
                "Justin Ruggiano",
                "Jim Shellenback",
                "Scott Baker",
                "Mike Napoli"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Texas Rangers: 48",
            "words": [
                "Bobby Witt",
                "Drew Hutchison",
                "Colby Lewis",
                "Scott Anderson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Diego Padres: 5",
            "words": [
                "Randy Bass",
                "Alexi Amarista",
                "Kevin Kouzmanoff",
                "Randy Ready"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Texas Rangers: 35",
            "words": [
                "Randy Bass",
                "Jim Adduci",
                "Mike Jorgensen",
                "Bob Lacey"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Phillies: 7",
            "words": [
                "Tony Curry",
                "Cy Blanton",
                "Buddy Blattner",
                "Ronny Cedeño"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Phillies: 5",
            "words": [
                "Mike Benjamin",
                "Ron Gant",
                "Ike Pearson",
                "Larry Cox"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1901",
            "words": [
                "George Rohe",
                "Danny Shay",
                "Harry Bay",
                "Dave Brain"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1939",
            "words": [
                "Alex Carrasquel",
                "Jess Dobernic",
                "Jake Early",
                "Les Fleming"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1993",
            "words": [
                "Phil Hiatt",
                "Mike Lansing",
                "Pat Meares",
                "Mike Mohler"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1998",
            "words": [
                "Vic Darensbourg",
                "Brian Daubach",
                "Charles Gipson",
                "Seth Greisinger"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Los Angeles Angels",
            "words": [
                "Phil Gosselin",
                "Juan Lagares",
                "Tim Lincecum",
                "AJ Ramos"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Pittsburgh Pirates",
            "words": [
                "Jim Fregosi",
                "Hank Greenberg",
                "Grant Jackson",
                "Vern Law"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Baltimore Orioles",
            "words": [
                "Roger McDowell",
                "Keith Moreland",
                "Jim Northrup",
                "Erv Palica"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Atlanta Braves",
            "words": [
                "Norm Miller",
                "Jason Motte",
                "Biff Pocoroba",
                "Bob Priddy"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Philadelphia Phillies",
            "words": [
                "Andrew Romine",
                "Scott Rolen",
                "Larry Hisle",
                "Adam Eaton"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Florida Marlins",
            "words": [
                "Adrian Sampson",
                "Robert Andino",
                "Josh Wilson",
                "Jeremy Hermida"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the St. Louis Cardinals",
            "words": [
                "Bob Forsch",
                "Tim Jones",
                "Tripp Cromer",
                "Eli Marrero"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Texas Rangers",
            "words": [
                "Brian Bohanon",
                "John Danks",
                "Craig Monroe",
                "Dwayne Henry"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Pittsburgh Pirates",
            "words": [
                "Melky Cabrera",
                "Clay Carroll",
                "Joe Coleman",
                "Derrek Lee"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Baltimore Orioles",
            "words": [
                "Tom Underwood",
                "Jaret Wright",
                "Albert Belle",
                "Robinson Chirinos"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Washington Nationals",
            "words": [
                "Ryan Raburn",
                "Pedro Astacio",
                "Alcides Escobar",
                "Mike Guerra"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Giants",
            "words": [
                "Roy Joiner",
                "Alex Konikowski",
                "Buster Maynard",
                "Johnny Rucker"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Los Angeles Dodgers",
            "words": [
                "R.J. Reynolds",
                "Wesley Wright",
                "Chad Billingsley",
                "Steve Howe"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Minnesota Twins",
            "words": [
                "Mike Benjamin",
                "Todd Ritchie",
                "Pat Mahomes",
                "Brian Duensing"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Atlanta Braves",
            "words": [
                "Larry McWilliams",
                "Dennis Lewallyn",
                "Bruce Benedict",
                "Kelly Wunsch"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Toronto Blue Jays",
            "words": [
                "Mike Timlin",
                "Pat Hentgen",
                "Shaun Marcum",
                "Adam Stern"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cincinnati Reds: 62",
            "words": [
                "Justin Lehr",
                "Ray Olmedo",
                "Wally Berger",
                "Trevor Bell"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago White Sox: 38",
            "words": [
                "Phil Regan",
                "Barry Jones",
                "Jim Willoughby",
                "Ken Patterson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Texas Rangers: 6",
            "words": [
                "Esteban Beltré",
                "Johnny Grubb",
                "Esteban Germán",
                "Don Mincher"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Boston Red Sox: 10",
            "words": [
                "Esteban Beltré",
                "Shawn Wooten",
                "José Iglesias",
                "Billy Harrell"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Bugs Raymond",
                "Joe Presko",
                "Bill Fleming",
                "Jim Nettles"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Bill Voss",
                "Dan Jennings",
                "Orlando Mercado",
                "Faye Throneberry"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 22 seasons",
            "words": [
                "Dave Winfield",
                "Sad Sam Jones",
                "Harry Davis",
                "Bill Buckner"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Tucker Ashford",
                "John Boozer",
                "Leo Burke",
                "Slim Jones"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Brooklyn Dodgers",
            "words": [
                "Al Epperly",
                "Freddie Lindstrom",
                "Lew Riggs",
                "Watson Clark"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Philadelphia Phillies",
            "words": [
                "Merv Shea",
                "Dick Coffman",
                "Syl Johnson",
                "Jerry Koosman"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Boston Red Sox",
            "words": [
                "Jack Clark",
                "Mickey Owen",
                "Bret Saberhagen",
                "Mike Timlin"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Texas Rangers",
            "words": [
                "Oddibe McDowell",
                "Juan Nicasio",
                "Dave Rozema",
                "John Russell"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Toronto Blue Jays",
            "words": [
                "Randy Knorr",
                "Ryan Roberts",
                "Brett Cecil",
                "Derek Bell"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Diego Padres",
            "words": [
                "Derrek Lee",
                "Bob Milacki",
                "Bill Almon",
                "Jim Crawford"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Atlanta Braves",
            "words": [
                "Steve Avery",
                "Tyler Houston",
                "John Rocker",
                "Ryan Klesko"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Boston Red Sox",
            "words": [
                "Ryan Lavarnway",
                "Mark Teixeira",
                "Justin Grimm",
                "Chris Reitsma"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Los Angeles Angels of Anaheim",
            "words": [
                "Jason Christiansen",
                "C.J. Wilson",
                "Joel Piñeiro",
                "Justin Speier"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Francisco Giants",
            "words": [
                "Duane Kuiper",
                "Billy Loes",
                "Bob Nieman",
                "Dan Quisenberry"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Los Angeles Dodgers",
            "words": [
                "Pete Mikkelsen",
                "Aaron Miles",
                "Justin Miller",
                "Rodney Myers"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Pittsburgh Pirates",
            "words": [
                "Brad Clontz",
                "Dick Davis",
                "Jerry Dybzinski",
                "Hal Finney"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Atlanta Braves: 18",
            "words": [
                "Jim Presley",
                "Jarrod Saltalamacchia",
                "Pablo Sandoval",
                "Ryan Klesko"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Atlanta Braves: 30",
            "words": [
                "Mike Stanton",
                "Elliot Johnson",
                "Ryan Lavarnway",
                "Ray Holbert"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cleveland Indians: 56",
            "words": [
                "Bruce Aven",
                "Rod Craig",
                "Fernando Cabrera",
                "Frank Herrmann"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Florida Marlins: 29",
            "words": [
                "Bruce Aven",
                "Gaby Sánchez",
                "Jacque Jones",
                "Joe Siddall"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Oakland Athletics",
            "words": [
                "Steve McCatty",
                "Mike Mohler",
                "Mark Mulder",
                "Jeff Newman"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Florida Marlins",
            "words": [
                "Michael Tejera",
                "Marc Valdes",
                "Quilvio Veras",
                "Ross Wolf"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Kansas City Royals",
            "words": [
                "Rich Thompson",
                "Joe Vitiello",
                "Frank Wills",
                "Blake Wood"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Philadelphia Phillies",
            "words": [
                "Vance Worley",
                "Mike Anderson",
                "Antonio Bastardo",
                "Bobby Bragan"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Boston Red Sox",
            "words": [
                "Don Aase",
                "Jed Lowrie",
                "Shea Hillenbrand",
                "Justin Masterson"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Oakland Athletics",
            "words": [
                "Floyd Bannister",
                "Jared Burton",
                "Todd Burns",
                "Dave Beard"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Los Angeles Dodgers",
            "words": [
                "Tracy Woodson",
                "Justin Ruggiano",
                "Brady Raggio",
                "Matt Magill"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Houston Astros",
            "words": [
                "Brian Sikorski",
                "Donnie Hill",
                "Danny Walton",
                "Rio Ruiz"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1987",
            "words": [
                "Jody Reed",
                "Danny Sheaffer",
                "Gary Thurman",
                "Greg Cadaret"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1915",
            "words": [
                "Johnny Miljus",
                "Art Nehf",
                "Ben Paschal",
                "Jack Smith"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1983",
            "words": [
                "Tom Foley",
                "Tim Hulett",
                "Bill Krueger",
                "Craig McMurtry"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2016",
            "words": [
                "Dan Altavilla",
                "Joe Biagini",
                "Andy Burns",
                "Grant Dayton"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2012",
            "words": [
                "Nori Aoki",
                "Quintin Berry",
                "Carter Capps",
                "Josh Edgin"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2004",
            "words": [
                "Daniel Cabrera",
                "Lenny DiNardo",
                "Mike Gosling",
                "Andy Green"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1935",
            "words": [
                "Ted Bond",
                "Clay Bryant",
                "Carl Doyle",
                "Lee Gamble"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1939",
            "words": [
                "Vern Olsen",
                "Marius Russo",
                "Hal Spindel",
                "Maurice Van Robays"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1885",
            "words": [
                "Reddy Mack",
                "Al Mays",
                "Toad Ramsey",
                "Edward Tate"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1990",
            "words": [
                "Bernard Gilkey",
                "Craig Grebeck",
                "Mark Leiter",
                "Alan Mills"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1944",
            "words": [
                "Howie Fox",
                "Joe Page",
                "Eddie Lopat",
                "Ralph Branca"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1884",
            "words": [
                "Sam Barkley",
                "Henry Boyle",
                "Jim Conway",
                "Chris Fulmer"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Washington Nationals",
            "words": [
                "Julián Tavárez",
                "Ryan Zimmerman",
                "Ossie Bluege",
                "Goose Goslin"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Washington Senators",
            "words": [
                "Don Wert",
                "Norm Zauchin",
                "Doug Camilli",
                "Tom Cheney"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Minnesota Twins",
            "words": [
                "Phil Nevin",
                "Glen Perkins",
                "Kirby Puckett",
                "Brad Radke"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cleveland Indians",
            "words": [
                "Bob Lemon",
                "Jack Morris",
                "Camilo Pascual",
                "George Uhle"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Montreal Expos",
            "words": [
                "Terry Humphrey",
                "Bob James",
                "Kevin Foster",
                "Brian Johnson"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Pittsburgh Pirates",
            "words": [
                "Zach Duke",
                "Stan Belinda",
                "Dale Berra",
                "Brian Williams"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Minnesota Twins",
            "words": [
                "Ben Revere",
                "Justin Morneau",
                "Kirby Puckett",
                "Graig Nettles"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Montreal Expos",
            "words": [
                "Delino DeShields",
                "Grady Sizemore",
                "Chris Sabo",
                "Mike Thurman"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Toronto Blue Jays",
            "words": [
                "Gary Glover",
                "Jason Wood",
                "Chris Carpenter",
                "Vinnie Chulk"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Arizona Diamondbacks",
            "words": [
                "Matt Davidson",
                "Mike Koplove",
                "Chad Tracy",
                "Andy Green"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago Cubs",
            "words": [
                "Bradley Zimmer",
                "Ken Holtzman",
                "Jeff Cirillo",
                "Dale Thayer"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the California Angels",
            "words": [
                "Joe Randa",
                "Robb Quinlan",
                "Jim Wohlford",
                "Jamie Burke"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Kansas City Athletics: 3",
            "words": [
                "Tom Saffell",
                "Don Bollweg",
                "Mike Hershberger",
                "Roger Maris"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago White Sox: 25",
            "words": [
                "Scott Bradley",
                "Donn Pall",
                "Oris Hockett",
                "Bobby Adams"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Seattle Mariners: 17",
            "words": [
                "Matt Sinatro",
                "Keith Mitchell",
                "Jim Presley",
                "Justin Smoak"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Florida Marlins: 21",
            "words": [
                "John Baker",
                "Jason Wood",
                "Josh Beckett",
                "Chuck Carr"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Mets",
            "words": [
                "Bruce Boisclair",
                "John Pacella",
                "Kevin Plawecki",
                "Ron Cey"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Baltimore Orioles",
            "words": [
                "Dick Ruthven",
                "Bobby Chouinard",
                "Jimmy Haynes",
                "Bubba Trammell"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Texas Rangers",
            "words": [
                "Rusty Greer",
                "Britt Reames",
                "Tim Stoddard",
                "Jeff Kunkel"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Kansas City Royals",
            "words": [
                "John Wathan",
                "Clint Hurdle",
                "Willie Wilson",
                "Dane Sardinha"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1954",
            "words": [
                "Russ Kemmerer",
                "Art Fowler",
                "Don Hoak",
                "Ryne Duren"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1947",
            "words": [
                "George Crowe",
                "Willie Jones",
                "Ted Kluszewski",
                "Gerry Staley"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1948",
            "words": [
                "Vern Bickford",
                "Bill Greason",
                "Howie Judson",
                "Dick Kokos"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2013",
            "words": [
                "Evan Gattis",
                "Brandon Maurer",
                "Vidal Nuno III",
                "Jim Adduci"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago White Sox",
            "words": [
                "Mark Buehrle",
                "Jerry Hairston",
                "Tyler Danish",
                "Chris Getz"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Houston Astros",
            "words": [
                "Mark Knudson",
                "Mark Ross",
                "Greg Hibbard",
                "Lance McCullers Jr."
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Seattle Mariners",
            "words": [
                "David Purcey",
                "Matt Tuiasosopo",
                "John Axford",
                "Aaron Myette"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Atlanta Braves",
            "words": [
                "Dale Murphy",
                "Cory Gearrin",
                "Nick Green",
                "Mike Brown"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago White Sox",
            "words": [
                "Tyler Danish",
                "Ike Eichrodt",
                "Marv Foley",
                "Ramón García"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for San Diego Padres",
            "words": [
                "Sean Kazmar Jr.",
                "Wade LeBlanc",
                "Wil Nieves",
                "Bob Patterson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Toronto Blue Jays",
            "words": [
                "Dustin McGowan",
                "Aaron Small",
                "Mitch Webster",
                "Chris Woodward"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Boston Red Sox",
            "words": [
                "Billy Goodman",
                "Jon Lester",
                "Sparky Lyle",
                "Ron Mahay"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Mets",
            "words": [
                "Jharel Cotton",
                "Rusty Tillman",
                "Walt Terrell",
                "Brandon Lyon"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Houston Astros",
            "words": [
                "Josh Lindblom",
                "Jason Varitek",
                "Bill Greif",
                "Donne Wall"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the California Angels",
            "words": [
                "Willie Fraser",
                "Paul Dade",
                "Brad Havens",
                "Kevin Romine"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Cincinnati Reds",
            "words": [
                "Ken Griffey",
                "Danny Tartabull",
                "Austin Kearns",
                "Jake Arrieta"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Oakland Athletics: 30",
            "words": [
                "Willie Randolph",
                "Brett Anderson",
                "Ryan Lavarnway",
                "Aaron Small"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Boston Red Sox: 53",
            "words": [
                "Sammy Stewart",
                "Fernando Cabrera",
                "Andy Abad",
                "Brendan Donnelly"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cincinnati Reds: 54",
            "words": [
                "Andy Abad",
                "Tom Browning",
                "Bill Selby",
                "Jolbert Cabrera"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Phillies: 47",
            "words": [
                "Endy Chávez",
                "Larry Andersen",
                "Howie Kendrick",
                "Kyle Abbott"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Giants",
            "words": [
                "Bill Connelly",
                "Chuck Dressen",
                "Joe Garagiola",
                "Jack Hallett"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Colorado Rockies",
            "words": [
                "John Habyan",
                "José Iglesias",
                "Nelson Liriano",
                "Bruce Ruffin"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cincinnati Reds",
            "words": [
                "Vic Correll",
                "Al Ferrara",
                "Jack Hannahan",
                "Hernán Iribarren"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago White Sox",
            "words": [
                "Jim Willoughby",
                "George Yankowski",
                "Glenn Borgmann",
                "Brian Bruney"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "Preston Wilson",
                "Jake Flowers",
                "Randy Jackson",
                "Ted Lepcio"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 11 seasons",
            "words": [
                "Red Ehret",
                "Jackie Jensen",
                "Terris McDuffie",
                "Red Barrett"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 16 seasons",
            "words": [
                "Jason Isringhausen",
                "Glenn Myatt",
                "Ed Delahanty",
                "Roy Halladay"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Junior Noboa",
                "Vic Correll",
                "Jerry DaVanon",
                "Kelly Downs"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Yankees",
            "words": [
                "Mickey Scott",
                "Andy Fox",
                "Ron Blomberg",
                "Steve Rogers"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Francisco Giants",
            "words": [
                "Bob Kearney",
                "Rich Folkers",
                "Ron Bryant",
                "Warren Brusstar"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Kansas City Royals",
            "words": [
                "Mike Montgomery",
                "Larry Sutton",
                "Brian Sanches",
                "Deion Sanders"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Pittsburgh Pirates",
            "words": [
                "Dustin Hermanson",
                "Tim Wakefield",
                "Dave Parker",
                "Brad Eldred"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Yankees",
            "words": [
                "Chris Bootcheck",
                "Daryl Boston",
                "Rob Brantly",
                "Scott Brosius"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Philadelphia Phillies",
            "words": [
                "Saul Rogovin",
                "Dane Sardinha",
                "Michael Saunders",
                "Nick Strincevich"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cincinnati Reds",
            "words": [
                "Iván De Jesús",
                "Steve Delabar",
                "Charlie English",
                "Luke Farrell"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cleveland Indians",
            "words": [
                "Bob Kelly",
                "George Kontos",
                "Bill Laxton",
                "Dennis Lewallyn"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Seattle Mariners: 53",
            "words": [
                "Dan Altavilla",
                "Joaquín Benoit",
                "Willie Horton",
                "Bob Milacki"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Phillies: 40",
            "words": [
                "Warren Brusstar",
                "Andy Ashby",
                "Aaron Altherr",
                "Bob Boone"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Phillies: 23",
            "words": [
                "Plácido Polanco",
                "Jeff Stone",
                "Joe Thurston",
                "Paul Bako"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Francisco Giants: 8",
            "words": [
                "Wilson Delgado",
                "Aaron Altherr",
                "Damon Berryhill",
                "Jeff Keppinger"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1910",
            "words": [
                "Charlie Mullen",
                "Ralph Stroud",
                "Dutch Zwilling",
                "Alex McCarthy"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1926",
            "words": [
                "Denny Sothern",
                "Hal Wiltse",
                "Charles Zomphier",
                "Dave Barbee"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1933",
            "words": [
                "Ray Prim",
                "Hal Trosky",
                "Burgess Whitehead",
                "Buddy Armour"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1976",
            "words": [
                "Moose Haas",
                "Ruppert Jones",
                "Joe Sambito",
                "Joaquín Andújar"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1942",
            "words": [
                "Thurman Tucker",
                "Hank Wyse",
                "Tommy Holmes",
                "Clyde Kluttz"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1969",
            "words": [
                "Tony Muser",
                "Marty Perez",
                "Darrel Chaney",
                "Bobby Valentine"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1933",
            "words": [
                "Ray Dandridge",
                "Luke Hamlin",
                "Roy Henshaw",
                "Dick Barrett"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2005",
            "words": [
                "Edwin Encarnación",
                "Edinson Vólquez",
                "Jeff Mathis",
                "Ervin Santana"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 3 seasons",
            "words": [
                "Andy Drake",
                "Joe Altobelli",
                "Gene Smith",
                "Telosh Howard"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 9 seasons",
            "words": [
                "Wayne Garland",
                "Jackie Collum",
                "Whitey Wietelmann",
                "Moose Solters"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "Terry Whitfield",
                "Joey Amalfitano",
                "Nate Jones",
                "Henry Spearman"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 17 seasons",
            "words": [
                "Steve Gromek",
                "Buck Martinez",
                "Arlie Latham",
                "Harry Heilmann"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1906",
            "words": [
                "Rube Kroh",
                "Jim Moroney",
                "Lew Richie",
                "Johnny Bates"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1956",
            "words": [
                "Mike McCormick",
                "Moe Drabowsky",
                "Bill Mazeroski",
                "Luis Aparicio"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1973",
            "words": [
                "Mike Easler",
                "Bill Madlock",
                "Charlie Moore",
                "Andre Thornton"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1962",
            "words": [
                "Ted Savage",
                "Floyd Weaver",
                "Al Weis",
                "Bob Burda"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Ginger Beaumont",
                "Russ Snyder",
                "Richard Dotson",
                "Ty Wigginton"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Darrell May",
                "Jim York",
                "Fred Beene",
                "Frank Menechino"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "Anthony Cooper",
                "Jim Delsing",
                "Eric Young Jr.",
                "Paul Minner"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 2 seasons",
            "words": [
                "Mike Trost",
                "Jerry Hurley",
                "Garland Shifflett",
                "Fuzz White"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1912",
            "words": [
                "Dan Griner",
                "Byron Houck",
                "Wally Mattick",
                "Ed Miller"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1979",
            "words": [
                "Phil Huffman",
                "Rusty Kuntz",
                "Jim Lewis",
                "Mike O'Berry"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1926",
            "words": [
                "Johnny Welch",
                "Ed Baecht",
                "Halley Harding",
                "Oscar Melillo"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1992",
            "words": [
                "Jim Tatum",
                "Jack Voigt",
                "Bobby Ayala",
                "Rafael Bournigal"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Diego Padres",
            "words": [
                "Tom Gorman",
                "Don Hahn",
                "Keone Kela",
                "Dave Marshall"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Washington Nationals",
            "words": [
                "Lefty Gomez",
                "Jeremy Guthrie",
                "Joe Haynes",
                "Firpo Marberry"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Tampa Bay Devil Rays",
            "words": [
                "Todd Ritchie",
                "Damon Hollins",
                "Brian Meadows",
                "Steve Parris"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cleveland Indians",
            "words": [
                "John Butcher",
                "Russ Christopher",
                "David Clyde",
                "Steve Comer"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2008",
            "words": [
                "Lou Marson",
                "Shairon Martis",
                "Kyle McClellan",
                "James McDonald"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2000",
            "words": [
                "Brady Clark",
                "Morgan Ensberg",
                "Matt Ginter",
                "Toby Hall"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1939",
            "words": [
                "Bennie Warren",
                "Whitey Wietelmann",
                "T.J. Brown",
                "Oscar Givens"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1983",
            "words": [
                "Spike Owen",
                "Gerald Perry",
                "Dennis Rasmussen",
                "Randy Ready"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Boston Bees",
            "words": [
                "Bobby Reis",
                "Tiny Chaplin",
                "Roy Johnson",
                "Joe Stripp"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Minnesota Twins",
            "words": [
                "Scott Stahoviak",
                "Clete Thomas",
                "John Verhoeven",
                "Jim Winn"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Diego Padres",
            "words": [
                "Phil Hughes",
                "Von Joshua",
                "Fred Kendall",
                "Doug Mirabelli"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Giants",
            "words": [
                "Kirby Higbe",
                "Tony Lazzeri",
                "Bill Terry",
                "Hank Thompson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago White Sox",
            "words": [
                "Dan Pasqua",
                "Ike Pearson",
                "Tony Rensa",
                "Buck Ross"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Diego Padres",
            "words": [
                "Chris Cannizzaro",
                "Brian Giles",
                "Danny Jackson",
                "Tom Lampkin"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Browns",
            "words": [
                "Archie McKain",
                "Howard Mills",
                "Tommy Thompson",
                "Russ Van Atta"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Texas Rangers",
            "words": [
                "Herbert Perry",
                "Fritz Peterson",
                "Mark Petkovsek",
                "Wandy Rodríguez"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1930",
            "words": [
                "Billy Werber",
                "Chester Williams",
                "Frank Doljack",
                "Jake Dunn"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1971",
            "words": [
                "Dave Rader",
                "Eric Soderholm",
                "Rusty Torres",
                "Rick Auerbach"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1909",
            "words": [
                "Bobby Vaughn",
                "Del Young",
                "Clyde Engle",
                "Rip Hagerman"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1910",
            "words": [
                "Hugh Bradley",
                "Sandy Burk",
                "Felix Chouinard",
                "Red Corriden"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1994",
            "words": [
                "Chad Ogea",
                "Brad Woodall",
                "Ray Holbert",
                "Paul Spoljaric"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1890",
            "words": [
                "George Hemming",
                "Pete Daniels",
                "Duke Esper",
                "Bill Gray"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1981",
            "words": [
                "Tom Brunansky",
                "Dave Henderson",
                "Kent Hrbek",
                "Tim Leary"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2002",
            "words": [
                "Ramón Santiago",
                "Marco Scutaro",
                "Jeremy Affeldt",
                "Willie Bloomquist"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the St. Louis Cardinals",
            "words": [
                "Tom Heintzelman",
                "Ray Searage",
                "Xavier Nady",
                "Skip Schumaker"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Arizona Diamondbacks",
            "words": [
                "Dan Uggla",
                "Jason Bulger",
                "Sergio Santos",
                "Casey Daigle"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Yankees",
            "words": [
                "Joba Chamberlain",
                "Hal Morris",
                "John Ryan Murphy",
                "Steve Balboni"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Mets",
            "words": [
                "Lee Mazzilli",
                "Neil Allen",
                "Jay Tibbs",
                "Eric Campbell"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Boston Red Sox",
            "words": [
                "Reggie Harris",
                "Jeff Bagwell",
                "Peter Munro",
                "Steve Lyons"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Seattle Mariners",
            "words": [
                "Brian Fuentes",
                "Patrick Kivlehan",
                "Mike Blowers",
                "Dann Bilardello"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Diego Padres",
            "words": [
                "Sean Burroughs",
                "David Freese",
                "Broderick Perkins",
                "Todd Helton"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Colorado Rockies",
            "words": [
                "Chris Iannetta",
                "Matt Reynolds",
                "Terry Jones",
                "Matt Holliday"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "Dale Long",
                "Eric Gunderson",
                "Don Hood",
                "Johnny Moore"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Brad Wellman",
                "Jesse Gonder",
                "Robb Quinlan",
                "Mickey Klutts"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Bob Allen",
                "Owen Friend",
                "Don Shaw",
                "Danny Murphy"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 19 seasons",
            "words": [
                "Barry Larkin",
                "Stuffy McInnis",
                "Ted Williams",
                "Syl Johnson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Montreal Expos",
            "words": [
                "Fred Lewis",
                "Dennis Blair",
                "Ian Desmond",
                "Brian Schneider"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Toronto Blue Jays",
            "words": [
                "Shawn Green",
                "Carlton Loewer",
                "Marc Rzepczynski",
                "Shannon Stewart"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Seattle Mariners",
            "words": [
                "Pat Lennon",
                "Adam Jones",
                "John Moses",
                "Dan Altavilla"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago Cubs",
            "words": [
                "Brandon Guyer",
                "Doug Glanville",
                "Casey McGehee",
                "Tom House"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 14 seasons",
            "words": [
                "Joel Youngblood",
                "Solly Hofman",
                "Mike LaCoss",
                "Dante Bichette"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 13 seasons",
            "words": [
                "Gary Sutherland",
                "Herm Wehmeier",
                "Luis Salazar",
                "Jim Kern"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 3 seasons",
            "words": [
                "Russ Meers",
                "Jim Bronstad",
                "Bill Wilson",
                "Albert Overton"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Eddie Dyer",
                "Bob Scott",
                "Bill Pulsipher",
                "Ralph Hodgin"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Oakland Athletics",
            "words": [
                "Todd Walker",
                "Bruce Bochte",
                "Ron Darling",
                "Ed Farmer"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Giants",
            "words": [
                "Rube Fischer",
                "Clint Hartung",
                "Mickey Haslin",
                "George Hausmann"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Baltimore Orioles",
            "words": [
                "Dave Boswell",
                "Cam Carreon",
                "Pat Clements",
                "Mike Cook"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago Cubs",
            "words": [
                "Taylor Douthit",
                "Jaime García",
                "Matt Guerrier",
                "Jeremy Jeffress"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1873",
            "words": [
                "Red Woodhead",
                "John Farrow",
                "Lou Say",
                "Jack Manning"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2009",
            "words": [
                "Julio Borbon",
                "David Huff",
                "Chris Johnson",
                "Jeff Manship"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1978",
            "words": [
                "Steve Baker",
                "Víctor Cruz",
                "Roger Erickson",
                "Karl Pagel"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1973",
            "words": [
                "Tom Heintzelman",
                "Wilbur Howard",
                "Larry Lintz",
                "Dick Pole"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1979",
            "words": [
                "John Tudor",
                "Gary Ward",
                "Danny Heep",
                "Tom Herr"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1963",
            "words": [
                "Jerry Stephenson",
                "Fred Talbot",
                "Jay Ward",
                "Larry Bearnarth"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1924",
            "words": [
                "John Henry Russell",
                "Bennie Tate",
                "Max Bishop",
                "Earle Combs"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1980",
            "words": [
                "Ken Schrom",
                "Ty Waller",
                "Rich Bordi",
                "Sal Butera"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Tom Edens",
                "Tony Cingrani",
                "Lewis Hampton",
                "Hung-Chih Kuo"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 18 seasons",
            "words": [
                "Joe Torre",
                "Ray Sadecki",
                "Dan Plesac",
                "Jimmy Ryan"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 2 seasons",
            "words": [
                "Harry Smith",
                "Sonny Boy Jeffries",
                "Adam Greenberg",
                "Kohly Miller"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Joe Roa",
                "Johnny Beazley",
                "Jake Elmore",
                "Cotton Tierney"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Toronto Blue Jays",
            "words": [
                "Jack Kucek",
                "Craig Kusick",
                "Andy LaRoche",
                "Brian Lesher"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Texas Rangers",
            "words": [
                "Ross Wolf",
                "Derek Dietrich",
                "Robert Ellis",
                "Mike Foltynewicz"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Detroit Tigers",
            "words": [
                "Billy Hitchcock",
                "Frank House",
                "Earl Johnson",
                "Eddie Lake"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Tampa Bay Devil Rays",
            "words": [
                "Jim Parque",
                "Brad Pennington",
                "John Rocker",
                "Jae Weong Seo"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Diego Padres: 14",
            "words": [
                "Carmelo Martínez",
                "Alberto González",
                "Josh Wilson",
                "Rob Bowen"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 4",
            "words": [
                "Ryan Freel",
                "Babe Herman",
                "Rob Bowen",
                "Joey Gathright"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Oakland Athletics: 17",
            "words": [
                "Adam Melhuse",
                "Adam Rosales",
                "Geovany Soto",
                "Ron Mahay"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Oakland Athletics: 18",
            "words": [
                "Ben Zobrist",
                "Orlando Cabrera",
                "Mike Jorgensen",
                "Dave McCarty"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Arizona Diamondbacks",
            "words": [
                "Ryan Cook",
                "Lance Cormier",
                "Jake Elmore",
                "Evan Marshall"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cleveland Indians",
            "words": [
                "Bob Allen",
                "Joe Altobelli",
                "Rick Austin",
                "Mike Bacsik"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago White Sox",
            "words": [
                "Vern Kennedy",
                "John Kerr",
                "Eddie Lopat",
                "Cass Michaels"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Tommy Byrne",
                "Melky Cabrera",
                "Myril Hoag",
                "Rex Hudler"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 15 seasons",
            "words": [
                "Bruce Kison",
                "Bill Sherdel",
                "Clint Brown",
                "Cliff Heathcote"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 17 seasons",
            "words": [
                "Billy Jurges",
                "Fernando Valenzuela",
                "Gus Mancuso",
                "Boog Powell"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Steve Evans",
                "Sam Dyson",
                "Chuck Carr",
                "Ryan Freel"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Jeff McKnight",
                "Marius Russo",
                "Joe Biagini",
                "Ryan Cook"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Toronto Blue Jays",
            "words": [
                "Marc Rzepczynski",
                "Eric Thames",
                "Al Woods",
                "Eddie Zosky"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Tampa Bay Devil Rays",
            "words": [
                "Delmon Young",
                "Matt Diaz",
                "Chad Gaudin",
                "Scott McClain"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Montreal Expos",
            "words": [
                "Bret Barberie",
                "Peter Bergeron",
                "Frank Bolick",
                "Reid Cornelius"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Philadelphia Phillies",
            "words": [
                "Mike Schmidt",
                "Bob Boone",
                "Rube Bressler",
                "Granny Hamner"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1976",
            "words": [
                "Bob Owchinko",
                "Greg Pryor",
                "Jason Thompson",
                "Len Barker"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1972",
            "words": [
                "Mick Kelleher",
                "Doc Medich",
                "Ken Reitz",
                "Dave Roberts"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1973",
            "words": [
                "Don Hood",
                "Bake McBride",
                "Mike Phillips",
                "Glenn Abbott"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2000",
            "words": [
                "Matt LeCroy",
                "Timo Pérez",
                "Tike Redman",
                "Danny Ardoin"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Mets: 35",
            "words": [
                "Orlando Mercado",
                "Joe Smith",
                "Randy Jones",
                "Dock Ellis"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Minnesota Twins: 20",
            "words": [
                "Don Lee",
                "Lew Ford",
                "Rich Reese",
                "Ken Sanders"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Detroit Tigers: 29",
            "words": [
                "Nate Robertson",
                "Vince Coleman",
                "Gerónimo Berroa",
                "Wendell Magee"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Oakland Athletics: 11",
            "words": [
                "Chris Snelling",
                "Tony Armas",
                "Scott Sauerbeck",
                "Johnnie LeMaster"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2001",
            "words": [
                "Andy Abad",
                "Larry Bigbie",
                "Ryan Drese",
                "Alex Escobar"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1987",
            "words": [
                "Randy Milligan",
                "Dave Otto",
                "Mark Williamson",
                "Gerald Young"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2009",
            "words": [
                "Brad Mills",
                "Jordan Schafer",
                "Dale Thayer",
                "Donnie Veal"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1962",
            "words": [
                "Merritt Ranew",
                "Dick Simpson",
                "Max Alvis",
                "Bo Belinsky"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Boston Red Sox",
            "words": [
                "Brandon Workman",
                "Tom Wright",
                "Norm Zauchin",
                "Luis Alvarado"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Milwaukee Brewers",
            "words": [
                "Rick Auerbach",
                "Kevin Barker",
                "Chris Bosio",
                "Valerio De Los Santos"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Brooklyn Dodgers",
            "words": [
                "Don Drysdale",
                "Gene Mauch",
                "John Roseboro",
                "Dick Siebert"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Toronto Blue Jays",
            "words": [
                "Garth Iorg",
                "Casey Janssen",
                "Sandy Martínez",
                "Julio Mosquera"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Boston Red Sox",
            "words": [
                "Dom DiMaggio",
                "J.D. Drew",
                "Bill Haselman",
                "Tommy Helms"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Detroit Tigers",
            "words": [
                "Drew Hutchison",
                "Mark Lowe",
                "Lynn Nelson",
                "Iván Nova"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Francisco Giants",
            "words": [
                "Duke Snider",
                "Chris Speier",
                "Billy Pierce",
                "Rick Reuschel"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Pittsburgh Pirates",
            "words": [
                "Corey Hart",
                "Sixto Lezcano",
                "Pat Mahomes",
                "Dave May"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Bill Phyle",
                "Charlie Henry",
                "Tommy Sheehan",
                "Joe Connor"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Blondie Purcell",
                "Glendon Rusch",
                "Bill Virdon",
                "Mitchell Murray"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 16 seasons",
            "words": [
                "Bobby Bonilla",
                "George Britt",
                "Vladimir Guerrero",
                "Frank Thomas"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 15 seasons",
            "words": [
                "Zach Duke",
                "Jason Kendall",
                "Greg Vaughn",
                "Joe Tinker"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Florida Marlins",
            "words": [
                "Logan Kensing",
                "Josh Johnson",
                "Scott Olsen",
                "Billy McMillon"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Baltimore Orioles",
            "words": [
                "Kevin Gross",
                "Rick Honeycutt",
                "Adam Loewen",
                "Rick Bauer"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Francisco Giants",
            "words": [
                "Travis Ishikawa",
                "Jack Clark",
                "Jonathan Albaladejo",
                "Mike Aldrete"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Los Angeles Angels of Anaheim",
            "words": [
                "Michael Kohn",
                "Sean O'Sullivan",
                "Garrett Richards",
                "Trevor Bell"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the California Angels",
            "words": [
                "Mike Witt",
                "Corky Miller",
                "Kyle Abbott",
                "Paul Sorrento"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Yankees",
            "words": [
                "Dave Eiland",
                "Stan Bahnsen",
                "Eric Plunk",
                "Mike Pagliarulo"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Baltimore Orioles",
            "words": [
                "Jim Hoey",
                "Storm Davis",
                "Mark Leiter",
                "Matt Wieters"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Pittsburgh Pirates",
            "words": [
                "Joel Skinner",
                "Chris Young",
                "Lance Johnson",
                "Hosken Powell"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1983",
            "words": [
                "Joe Carter",
                "Juan Samuel",
                "Tom Candiotti",
                "Otis Nixon"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1995",
            "words": [
                "Billy Wagner",
                "Gregg Zaun",
                "Mike Cameron",
                "Juan Castro"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1982",
            "words": [
                "Gary Redus",
                "Kevin Bass",
                "Jim Gott",
                "Tom Henke"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1899",
            "words": [
                "Frank Dillon",
                "Tom Fleming",
                "Lefty Herring",
                "Frank McManus"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cleveland Indians: 22",
            "words": [
                "Mike Fischlin",
                "Roy Weatherly",
                "Joe Shaute",
                "Mark Langston"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cleveland Indians: 17",
            "words": [
                "Miguel de la Hoz",
                "Al Rosen",
                "Marquis Grissom",
                "Yonder Alonso"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Washington Nationals: 31",
            "words": [
                "Steve Korcheck",
                "John Lannan",
                "Bill Zuber",
                "Cecil Travis"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Mets: 43",
            "words": [
                "Shane Spencer",
                "Todd Pratt",
                "Joe Ginsberg",
                "Rigo Beltrán"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1964",
            "words": [
                "Sonny Siebert",
                "Walt Williams",
                "Dave Duncan",
                "Alex Johnson"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1973",
            "words": [
                "Wayne Garland",
                "Jesse Jefferson",
                "Pete Mackanin",
                "Larry Cox"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2008",
            "words": [
                "John Baker",
                "Roger Bernadina",
                "Brooks Conrad",
                "Chris Dickerson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2006",
            "words": [
                "Jeff Karstens",
                "Josh Kinney",
                "Fred Lewis",
                "Mitch Maier"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1997",
            "words": [
                "Javier Valentín",
                "Jorge Velandia",
                "José Vidro",
                "Aaron Boone"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1917",
            "words": [
                "Aaron Ward",
                "Bob McGraw",
                "Hal Carlson",
                "Ira Flagstead"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1969",
            "words": [
                "Ken Rudolph",
                "Ron Blomberg",
                "Jim Breazeale",
                "Jim Colborn"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1940",
            "words": [
                "Lamb Barbee",
                "Jesse Douglas",
                "Chesley Gray",
                "Jim Mallory"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1913",
            "words": [
                "Doc Ayers",
                "Lynn Brenton",
                "Nick Cullop",
                "Tom Daly"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1992",
            "words": [
                "Melvin Nieves",
                "Roger Pavlik",
                "Bill Risley",
                "Andy Stankiewicz"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2013",
            "words": [
                "Preston Guilmet",
                "David Hale",
                "Mike Kickham",
                "Ian Krol"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2004",
            "words": [
                "Matt Treanor",
                "Wilson Valdez",
                "Travis Blackley",
                "Dave Bush"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the California Angels",
            "words": [
                "Dick Schofield",
                "Shawn Hillegas",
                "Chris Gwynn",
                "Jacob Cruz"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Florida Marlins",
            "words": [
                "Chris Volstad",
                "Michael Tejera",
                "Tony Watson",
                "Ken Giles"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the St. Louis Cardinals",
            "words": [
                "John Butcher",
                "Lenny Randle",
                "Daniel Descalso",
                "Larry Herndon"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Pittsburgh Pirates",
            "words": [
                "Tony Womack",
                "Joe Orsulak",
                "Kane Davis",
                "Ernie Camacho"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1901",
            "words": [
                "Bob Wicker",
                "John Butler",
                "Lefty Davis",
                "Bill Hallman"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1930",
            "words": [
                "Jack Burns",
                "Hal Lee",
                "Ray Phelps",
                "George Puccinelli"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1946",
            "words": [
                "Monty Kennedy",
                "Bob Ramazzotti",
                "Bill Rigney",
                "Dick Sisler"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1917",
            "words": [
                "Gene Bailey",
                "Rollie Naylor",
                "Herman Pillette",
                "Johnny Couch"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cleveland Indians",
            "words": [
                "Clint Brown",
                "Asdrúbal Cabrera",
                "Coco Crisp",
                "Wes Ferrell"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Boston Red Sox",
            "words": [
                "Ken Brett",
                "John Curtis",
                "Bobby Doerr",
                "Bruce Hurst"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Giants",
            "words": [
                "Hank Leiber",
                "Sam Leslie",
                "George Myatt",
                "Fresco Thompson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Rocky Nelson",
                "Donovan Osborne",
                "Lew Riggs",
                "Allen Sothoron"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Yankees: 63",
            "words": [
                "Mike Morgan",
                "Alberto González",
                "Mason Williams",
                "Randy Keisler"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Brooklyn Dodgers: 29",
            "words": [
                "Jimmy Wasdell",
                "Harry Eisenstat",
                "Clyde King",
                "Tom Winsett"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Pittsburgh Pirates: 24",
            "words": [
                "Dick Groat",
                "Tommy Helms",
                "Hank Borowy",
                "Manny Jiménez"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Francisco Giants: 50",
            "words": [
                "Jim Willoughby",
                "Brett Tomko",
                "Kevin Correia",
                "John Montefusco"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Los Angeles Dodgers",
            "words": [
                "Jason Repko",
                "Adam Riggs",
                "Tony Cruz",
                "A.J. Ellis"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Kansas City Royals",
            "words": [
                "Jamie Quirk",
                "Atlee Hammaker",
                "Kevin Seitzer",
                "Dave Johnson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Baltimore Orioles",
            "words": [
                "Nolan Reimold",
                "Larry Bigbie",
                "Doug Rau",
                "Darnell McDonald"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Cincinnati Reds",
            "words": [
                "Bill Dawley",
                "Tony Cingrani",
                "Chris Denorfia",
                "Terry McGriff"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "George Sweatt",
                "Al Osuna",
                "Renie Martin",
                "Bobby Reeves"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 13 seasons",
            "words": [
                "Lou Bierbauer",
                "Mike Scioscia",
                "Ralph Garr",
                "Jeff Montgomery"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 3 seasons",
            "words": [
                "Leo Hannibal",
                "Corban Joseph",
                "Luis Pillot",
                "Casey Daigle"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "Doug Fister",
                "Alex Grammas",
                "Ted Kubiak",
                "Bug Holliday"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Boston Red Sox",
            "words": [
                "Travis Shaw",
                "Mike Smithson",
                "Jeff Stone",
                "Randy Williams"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Texas Rangers",
            "words": [
                "Mike Schooler",
                "Chad Allen",
                "Jim Anderson",
                "Jack Armstrong"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Toronto Blue Jays",
            "words": [
                "A.J. Cole",
                "Rhiner Cruz",
                "Chris Getz",
                "Tim Johnson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Browns",
            "words": [
                "Les Tietje",
                "Frank Biscan",
                "Jess Doyle",
                "Bob Malloy"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Allan Anderson",
                "Brad Mills",
                "Nick Allen",
                "Rich Hinton"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Craig Swan",
                "Leon Wagner",
                "Geno Petralli",
                "Connie Ryan"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Art Reinhart",
                "Mike Christopher",
                "Vern Benson",
                "David Pauley"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "Casey Kotchman",
                "Keith Osik",
                "Mike McCormick",
                "Joe Battin"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Toronto Blue Jays",
            "words": [
                "Luis Sojo",
                "Shannon Stewart",
                "Dewayne Wise",
                "Jim Clancy"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago White Sox",
            "words": [
                "Harold Baines",
                "Dave Philley",
                "Rich Gossage",
                "Ted Lyons"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Boston Red Sox",
            "words": [
                "Charlie Berry",
                "Rick Burleson",
                "Dom DiMaggio",
                "John Flaherty"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Boston Bees",
            "words": [
                "Johnny Niggeling",
                "Nick Strincevich",
                "Jim Turner",
                "Whitey Wietelmann"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 15 seasons",
            "words": [
                "Elston Howard",
                "Paul Derringer",
                "Dickie Thon",
                "Denny Galehouse"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 11 seasons",
            "words": [
                "James Baldwin",
                "Eric Milton",
                "Scott Radinsky",
                "Tony Scott"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Ches Buchanan",
                "Dick Cole",
                "Bert Hunter",
                "J.B. Wendelken"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 14 seasons",
            "words": [
                "Pablo Sandoval",
                "Mickey Tettleton",
                "Bill Foster",
                "Ken Berry"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Bob Smith",
                "Randy Moffitt",
                "Elrod Hendricks",
                "Dave Goltz"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 11 seasons",
            "words": [
                "Lefty O'Doul",
                "Tim Laker",
                "Todd Worrell",
                "Cliff Pennington"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 13 seasons",
            "words": [
                "Ron Darling",
                "Steve Pearce",
                "Jamie Easterly",
                "Tommy Griffith"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Julio Valera",
                "Eufemio Abreu",
                "Arnold Hauser",
                "Ewald Pyle"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cincinnati Reds",
            "words": [
                "Chris Jones",
                "Alex Kampouris",
                "Junior Kennedy",
                "Mike Leake"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for San Francisco Giants",
            "words": [
                "Orlando Cepeda",
                "Royce Clayton",
                "Ryan Vogelsong",
                "Matt Williams"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for San Francisco Giants",
            "words": [
                "Eliézer Alfonzo",
                "Chris Arnold",
                "Jamie Brewington",
                "Chris Brown"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Mets",
            "words": [
                "Marco Scutaro",
                "Kevin Tapani",
                "Alex Treviño",
                "Wally Backman"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Mets: 26",
            "words": [
                "Dave Kingman",
                "Orlando Hernández",
                "Terrence Long",
                "Terry Leach"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Mets: 4",
            "words": [
                "John Valentin",
                "Jed Lowrie",
                "Albert Almora",
                "Ángel Berroa"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Athletics: 6",
            "words": [
                "Al Simmons",
                "Lou Finney",
                "Joe Boley",
                "Carmen Mauro"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Montreal Expos: 55",
            "words": [
                "Frank Bolick",
                "John Dopson",
                "Robert Machado",
                "Dave Hostetler"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Detroit Tigers",
            "words": [
                "Rico Brogna",
                "Adam Wilk",
                "Matt Thornton",
                "Curtis Granderson"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Cincinnati Reds",
            "words": [
                "Mike LaCoss",
                "Darrel Chaney",
                "Ron Robinson",
                "Paul Gibson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Oakland Athletics",
            "words": [
                "Collin Cowgill",
                "Justin Smoak",
                "Mitchell Page",
                "Brent Gates"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Francisco Giants",
            "words": [
                "Mike Myers",
                "Charlie Hayes",
                "Joe Nathan",
                "Sergio Romo"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cincinnati Reds",
            "words": [
                "Hobie Landrith",
                "Jeff Russell",
                "Herm Wehmeier",
                "Dan Wilson"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Boston Red Sox",
            "words": [
                "Mel Almada",
                "Ken Aspromonte",
                "Milt Bolling",
                "Ted Bowsfield"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Detroit Tigers",
            "words": [
                "Dave Stegman",
                "Joe Sullivan",
                "Bubba Trammell",
                "Jim Walewander"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for San Francisco Giants",
            "words": [
                "Salomón Torres",
                "Felipe Alou",
                "Jesús Alou",
                "John Burkett"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago White Sox",
            "words": [
                "Bill Long",
                "Norberto Martin",
                "Ken McBride",
                "Matt Merullo"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Montreal Expos",
            "words": [
                "Steve Rogers",
                "Kirk Rueter",
                "Brian Schneider",
                "Bryn Smith"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Boston Red Sox",
            "words": [
                "Charlie Wagner",
                "Earl Webb",
                "Jack Wilson",
                "Tom Winsett"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Atlanta Braves",
            "words": [
                "Mike Minor",
                "Peter Moylan",
                "Gerald Perry",
                "Rafael Ramírez"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Darryl Kile",
                "Dale Murray",
                "Dan McGann",
                "John Tudor"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Sam Horn",
                "Eddie Pellagrini",
                "Randy Niemann",
                "George Decker"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "John Donaldson",
                "Harry Wheeler",
                "Mike Matthews",
                "Steve Lombardozzi"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 13 seasons",
            "words": [
                "Bullet Rogan",
                "Red Donahue",
                "Rico Petrocelli",
                "Joe Blanton"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Mets: 23",
            "words": [
                "Matt Lawton",
                "Bernard Gilkey",
                "Matt den Dekker",
                "Aaron Altherr"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Pittsburgh Pirates: 15",
            "words": [
                "Andy LaRoche",
                "Bill Brubaker",
                "Enrique Romo",
                "Lloyd Waner"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Pittsburgh Pirates: 37",
            "words": [
                "Bob Kuzava",
                "Ed Stevens",
                "Burleigh Grimes",
                "Don Gross"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Minnesota Twins: 49",
            "words": [
                "Mike Hartley",
                "Sam Dyson",
                "Juan Minaya",
                "Kevin Jepsen"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Pittsburgh Pirates",
            "words": [
                "Tommy Thevenow",
                "Ed Whitson",
                "Bob Friend",
                "Dick Groat"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Seattle Mariners",
            "words": [
                "Steve Delabar",
                "Nick Franklin",
                "Mike Gardiner",
                "Sean Green"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for San Francisco Giants",
            "words": [
                "Jeff Brantley",
                "Shawn Estes",
                "Tito Fuentes",
                "Charlie Hayes"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Baltimore Orioles",
            "words": [
                "Arthur Rhodes",
                "Cal Ripken Jr.",
                "Dennis Martínez",
                "Brooks Robinson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Al Nipper",
                "Roy Mitchell",
                "William Ross",
                "Clyde King"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 17 seasons",
            "words": [
                "Mike Cameron",
                "Jack Doyle",
                "Michael Jackson",
                "Jeff Reed"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "John Kerr",
                "Dann Bilardello",
                "Charley Williams",
                "Red Embree"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 9 seasons",
            "words": [
                "Elmer Jacobs",
                "Owen Wilson",
                "Dalton Jones",
                "Mark Littell"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Kansas City Athletics",
            "words": [
                "Don Bollweg",
                "Chuck Essegian",
                "Gus Keriazakos",
                "Johnny Kucks"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Detroit Tigers",
            "words": [
                "Dean Palmer",
                "Paul Richards",
                "Gerry Staley",
                "Mickey Stanley"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Diego Padres",
            "words": [
                "Mark Kotsay",
                "Mickey Lolich",
                "Fred Lynn",
                "Johnny Podres"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Cardinals",
            "words": [
                "Vern Benson",
                "Ray Blades",
                "Ernie Camacho",
                "Roger Cedeño"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1967",
            "words": [
                "Amos Otis",
                "Rod Carew",
                "Jerry Koosman",
                "Tom Seaver"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1986",
            "words": [
                "Kevin Seitzer",
                "John Smiley",
                "Mitch Williams",
                "Scott Bailes"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1932",
            "words": [
                "Pete McQueen",
                "Roosevelt Tate",
                "Felton Wilson",
                "Sugar Cain"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1920",
            "words": [
                "Pie Traynor",
                "Bullet Rogan",
                "Andy Cooper",
                "Oscar Charleston"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Colorado Rockies",
            "words": [
                "Jody Gerut",
                "Jayson Nix",
                "Micah Owings",
                "Matt Garza"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Cincinnati Reds",
            "words": [
                "Kurt Stillwell",
                "Marc Valdes",
                "Buddy Carlyle",
                "Devin Mesoraco"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Detroit Tigers",
            "words": [
                "Jack Morris",
                "Rusty Meacham",
                "Jeremy Giambi",
                "Gene Lamont"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Toronto Blue Jays",
            "words": [
                "Jay Gibbons",
                "Xavier Hernandez",
                "Darin Mastroianni",
                "Aaron Sanchez"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Jim Coates",
                "Earle Combs",
                "Jim Deshaies",
                "Dámaso García"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago White Sox",
            "words": [
                "Gus Zernial",
                "Frenchy Bordagaray",
                "Chad Bradford",
                "Bucky Dent"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Baltimore Orioles",
            "words": [
                "Curt Schilling",
                "José Mesa",
                "Eddie Murray",
                "Lou Piniella"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Mets",
            "words": [
                "Aaron Ledesma",
                "Jeff McKnight",
                "Jenrry Mejía",
                "Lastings Milledge"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Kansas City Athletics",
            "words": [
                "Dave Hamilton",
                "Gene Tenace",
                "Rick Monday",
                "Reggie Jackson"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Pittsburgh Pirates",
            "words": [
                "Mike Fyhrie",
                "Matt Capps",
                "Dave Cash",
                "Casey Sadler"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Seattle Mariners",
            "words": [
                "Dustin Ackley",
                "Rich Harden",
                "Dave Mlicki",
                "Tony Phillips"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Milwaukee Brewers",
            "words": [
                "Mike Fiers",
                "Robin Yount",
                "Andrew Albers",
                "Tyler Thornburg"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago White Sox",
            "words": [
                "Shawn Abner",
                "Ollie Bejma",
                "Hiram Bithorn",
                "Bob Cain"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Colorado Rockies",
            "words": [
                "Zac Rosscup",
                "Matt Adams",
                "Yonder Alonso",
                "Micah Bowie"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Yankees",
            "words": [
                "Steve Howe",
                "Andruw Jones",
                "Paul O'Neill",
                "Jorge Posada"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Cardinals",
            "words": [
                "Art Howe",
                "Ron Hunt",
                "Hal Jeffcoat",
                "Alex Kellner"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Baltimore Orioles: 2",
            "words": [
                "Dilson Herrera",
                "Ryan Freel",
                "Neil Berry",
                "Bob Melvin"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 20",
            "words": [
                "Félix Pie",
                "Matt Mieske",
                "Grady Hatton",
                "Emil Kush"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "St. Louis Cardinals: 42",
            "words": [
                "Bruce Sutter",
                "Tom Cheney",
                "Curt Flood",
                "Ted Power"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "St. Louis Cardinals: 40",
            "words": [
                "Troy Percival",
                "Brian Fuentes",
                "Andy Benes",
                "Doug Clemens"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "St. Louis Cardinals: 33",
            "words": [
                "Carlos Villanueva",
                "Russell Branyan",
                "José Bautista",
                "Clay Carroll"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Oakland Athletics: 49",
            "words": [
                "Billy McMillon",
                "Greg McMichael",
                "Brett Anderson",
                "Mark Bellhorn"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Oakland Athletics: 48",
            "words": [
                "Eric Surkamp",
                "Michael Wuertz",
                "Joakim Soria",
                "Jim Corsi"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cincinnati Reds: 42",
            "words": [
                "Jim Brosnan",
                "Dusty Cooke",
                "Rick Mahler",
                "Michael Jackson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Phillies: 22",
            "words": [
                "Dane Iorg",
                "Tom Prince",
                "Schoolboy Rowe",
                "René Monteagudo"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Brooklyn Dodgers: 6",
            "words": [
                "Lefty O'Doul",
                "Buddy Hassett",
                "Carden Gillenwater",
                "Tony Giuliani"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Yankees: 45",
            "words": [
                "John Candelaria",
                "Rudy May",
                "Sergio Mitre",
                "Stan Bahnsen"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Athletics: 4",
            "words": [
                "Rabbit Warstler",
                "Don Bollweg",
                "Max Bishop",
                "Dib Williams"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Toronto Blue Jays",
            "words": [
                "Pat Kelly",
                "Mat Latos",
                "Tom Lawless",
                "Wil Ledezma"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Seattle Mariners",
            "words": [
                "Jerry Willard",
                "Milton Bradley",
                "Matt Lawton",
                "John Marzano"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Texas Rangers",
            "words": [
                "Nick Capra",
                "Don Carman",
                "Mark Clark",
                "Terry Clark"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Giants",
            "words": [
                "Glenn Spencer",
                "Leon Chagnon",
                "Sam Gibson",
                "Hooks Iott"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1990",
            "words": [
                "Brian McRae",
                "Mel Rojas",
                "Mike Simms",
                "Scott Aldred"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1975",
            "words": [
                "Preston Hanna",
                "Tom Hausman",
                "Butch Hobson",
                "Ken Kravec"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1956",
            "words": [
                "Earl Averill",
                "Jim Constable",
                "Chico Fernández",
                "Whitey Herzog"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1951",
            "words": [
                "Ed Blake",
                "Jim Brideweser",
                "Bob Chakales",
                "Mel Clark"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Tom Cheney",
                "Mark Clark",
                "Héctor Cruz",
                "Paul Dean"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cincinnati Reds",
            "words": [
                "Frank Duffy",
                "Ival Goodman",
                "Johnny Hetki",
                "Paul Janish"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Colorado Rockies",
            "words": [
                "Seth Smith",
                "John Thomson",
                "Jeff Francis",
                "Ubaldo Jiménez"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Ralph Houk",
                "Chad Huffman",
                "Randy Keisler",
                "Steve Kline"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Clete Thomas",
                "Harley Boss",
                "Paul Ratliff",
                "Jim Walewander"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Jing Johnson",
                "Alan Strange",
                "Willis Roberts",
                "Royce Ring"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 11 seasons",
            "words": [
                "Mike Trombley",
                "Kelly Johnson",
                "Len Barker",
                "Gordon Jones"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "George Pinkney",
                "Mace Brown",
                "Oil Can Boyd",
                "Jim Clinton"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago Cubs",
            "words": [
                "Todd Wellemeyer",
                "Michael Wuertz",
                "Matt Alexander",
                "George Altman"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Kansas City Royals",
            "words": [
                "Frank White",
                "Bob McClure",
                "Willie Wilson",
                "Carlos Beltrán"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Verne Clemons",
                "Bill DeLancey",
                "Luis DeLeón",
                "Bob Duliba"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cleveland Indians",
            "words": [
                "Jolbert Cabrera",
                "Einar Díaz",
                "Steve Farr",
                "Charles George"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Houston Astros",
            "words": [
                "Delino DeShields",
                "Craig Biggio",
                "Tom Murphy",
                "Roy Oswalt"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Detroit Tigers",
            "words": [
                "Andy Messersmith",
                "Glenn Wilson",
                "Bob Molinaro",
                "Adam Pettyjohn"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Philadelphia Phillies",
            "words": [
                "Mark Clear",
                "Bobby Korecky",
                "Dickie Noles",
                "Charles Hudson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Francisco Giants",
            "words": [
                "Jason Ellison",
                "Gary Matthews",
                "Phil Huffman",
                "Adam Hyzdu"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1995",
            "words": [
                "Roger Cedeño",
                "Jason Christiansen",
                "Doug Creek",
                "Bobby Higginson"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2003",
            "words": [
                "Aquilino López",
                "Kyle Snyder",
                "Óscar Villarreal",
                "Les Walrond"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1962",
            "words": [
                "Frank Kreutzer",
                "Don Lock",
                "Cap Peterson",
                "Dick Radatz"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1996",
            "words": [
                "Keith Osik",
                "Amaury Telemaco",
                "Justin Thompson",
                "Paul Wilson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Roger Repoz",
                "Rubén Rivera",
                "Aaron Robinson",
                "Ellie Rodríguez"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Houston Astros",
            "words": [
                "Chris Holt",
                "James Hoyt",
                "John Hudek",
                "Charlie Kerfeld"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago Cubs",
            "words": [
                "Johnny Schmitz",
                "Zack Taylor",
                "Steve Trachsel",
                "Lon Warneke"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Boston Red Sox",
            "words": [
                "Frank Sullivan",
                "John Valentin",
                "Hy Vandenberg",
                "Rabbit Warstler"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Boston Red Sox",
            "words": [
                "Fred Hatfield",
                "Sam Horn",
                "Tex Hughson",
                "Dalton Jones"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Kansas City Royals",
            "words": [
                "Jeremy Affeldt",
                "Andrés Blanco",
                "Endy Chávez",
                "Alex Gordon"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Charlie Sands",
                "George Selkirk",
                "Spec Shea",
                "Charlie Spikes"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Texas Rangers",
            "words": [
                "Laynce Nix",
                "Mark Petkovsek",
                "Greg Pryor",
                "Justin Smoak"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago White Sox",
            "words": [
                "Sammy Ellis",
                "Danny Farquhar",
                "Héctor Giménez",
                "Ryan Goins"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Toronto Blue Jays",
            "words": [
                "B.J. Ryan",
                "Randy St. Claire",
                "Ozzie Virgil",
                "Edgardo Alfonzo"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Montreal Expos",
            "words": [
                "Jeff Treadway",
                "Joe Vitiello",
                "Fred Whitfield",
                "Tim Blackwell"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago Cubs",
            "words": [
                "Sheldon Jones",
                "Ken Kravec",
                "Ed Lynch",
                "Stu Martin"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Detroit Tigers",
            "words": [
                "Al Alburquerque",
                "George Alusik",
                "Doug Baker",
                "Adam Bernero"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Yankees",
            "words": [
                "Jorge Posada",
                "Dave Righetti",
                "J.T. Snow",
                "Dave Bergman"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Seattle Mariners",
            "words": [
                "Marc Newfield",
                "Clay Parker",
                "Enrique Romo",
                "Mike Schooler"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Florida Marlins",
            "words": [
                "Mike Redmond",
                "Josh Beckett",
                "Carl Everett",
                "Luis Castillo"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Houston Astros",
            "words": [
                "Paul Householder",
                "Philip Humber",
                "T.J. Mathews",
                "Lance McCullers Jr."
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Los Angeles Dodgers",
            "words": [
                "Bob Bailor",
                "Scott Baker",
                "Andy Carey",
                "Wes Covington"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Toronto Blue Jays",
            "words": [
                "Steve Nicosia",
                "Juan Pérez",
                "Josh Thole",
                "Brad Wilkerson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Texas Rangers",
            "words": [
                "Travis Blackley",
                "Tommy Boggs",
                "Oil Can Boyd",
                "Adrian Brown"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Atlanta Braves",
            "words": [
                "Will Cunnane",
                "Adrian Devine",
                "Alex Dickerson",
                "Dick Dietz"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago Cubs",
            "words": [
                "Jeff Huson",
                "Darren Lewis",
                "José Lobatón",
                "Rodrigo López"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Boston Red Sox",
            "words": [
                "Gary Peters",
                "Rico Petrocelli",
                "Dick Williams",
                "Lou Boudreau"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Yankees",
            "words": [
                "Darren O'Day",
                "Bob Ojeda",
                "Ben Paschal",
                "Earl Torgeson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for California Angels",
            "words": [
                "Winston Llenas",
                "Gary Lucas",
                "Carlos Martínez",
                "John Montague"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Milwaukee Brewers",
            "words": [
                "Ryan Thompson",
                "Bill Wegman",
                "Len Barker",
                "David Bell"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago Cubs",
            "words": [
                "Carlos González",
                "Steve Hamilton",
                "George Mitterwald",
                "Brandon Morrow"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Kansas City Royals",
            "words": [
                "Gene Garber",
                "Hal McRae",
                "George Brett",
                "Lindy McDaniel"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 25",
            "words": [
                "Karl Rhodes",
                "Scot Thompson",
                "Troy O'Leary",
                "Gary Woods"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Philadelphia Athletics: 21",
            "words": [
                "Sam Zoldak",
                "Roy Parmelee",
                "Ed Heusser",
                "Bill Nicholson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cleveland Indians: 35",
            "words": [
                "Wayne Kirby",
                "Pete Donohue",
                "Mike Christopher",
                "Jack Salveson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cleveland Indians: 23",
            "words": [
                "Chris Bando",
                "Luis Aguayo",
                "Hal Naragon",
                "Jeromy Burnitz"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Brooklyn Dodgers",
            "words": [
                "Ray Phelps",
                "Tommy Tatum",
                "Harry Taylor",
                "Les Webber"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Washington Nationals",
            "words": [
                "Stephen Strasburg",
                "Jordan Zimmermann",
                "Jackie Hayes",
                "Joe Haynes"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Paul Derringer",
                "Larry Herndon",
                "Brian Jordan",
                "Tony Kaufmann"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Minnesota Twins",
            "words": [
                "Paul Ratliff",
                "Mark Redman",
                "Rich Reese",
                "Juan Rincón"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Seattle Mariners: 39",
            "words": [
                "Rafael Soriano",
                "Aaron Harang",
                "Erik Hanson",
                "Robert Machado"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Minnesota Twins: 38",
            "words": [
                "Randy St. Claire",
                "Cory Gearrin",
                "Geoff Zahn",
                "Luis Rodríguez"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Toronto Blue Jays: 31",
            "words": [
                "Shawn Hill",
                "John Parrish",
                "Andy LaRoche",
                "Brandon Lyon"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Houston Astros: 29",
            "words": [
                "J.R. Phillips",
                "Al Osuna",
                "Tony Sipp",
                "Octavio Dotel"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1882",
            "words": [
                "Charlie Buffinton",
                "William Robinson",
                "Chicken Wolf",
                "Cliff Carroll"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1889",
            "words": [
                "Charlie Reilly",
                "Jack Crooks",
                "Bug Holliday",
                "Dusty Miller"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1948",
            "words": [
                "Dick Weik",
                "Tony Jacobs",
                "Al Papai",
                "George Shuba"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1872",
            "words": [
                "Candy Cummings",
                "Herman Dehlman",
                "Nat Hicks",
                "Herm Doscher"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Kansas City Royals: 23",
            "words": [
                "César Gerónimo",
                "Al Fitzmorris",
                "Mark Gubicza",
                "Carlos Peña"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Francisco Giants: 23",
            "words": [
                "José Uribe",
                "Enos Cabell",
                "Tito Fuentes",
                "Ryan Garko"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Seattle Mariners: 8",
            "words": [
                "Justin Upton",
                "Jeremy Reed",
                "Mike Leake",
                "Larry Milbourne"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Houston Astros: 3",
            "words": [
                "Buddy Biancalana",
                "Kirt Manwaring",
                "Kazuo Matsui",
                "Rick Wilkins"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Atlanta Braves",
            "words": [
                "John Ennis",
                "Keith Mitchell",
                "Andrew Cashner",
                "Dusty Baker"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago White Sox",
            "words": [
                "Scott Radinsky",
                "Chris Carter",
                "Buddy Groom",
                "Mike Sirotka"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Cleveland Indians",
            "words": [
                "Seth Greisinger",
                "Aaron Laffey",
                "John Curtis",
                "Mike Bacsik"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Toronto Blue Jays",
            "words": [
                "Ben Weber",
                "Brandon Duckworth",
                "Craig Wilson",
                "Jeff Kaiser"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Oakland Athletics",
            "words": [
                "Tom Candiotti",
                "Rico Carty",
                "Ron Cey",
                "Brian Harper"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cleveland Indians",
            "words": [
                "Jack Harshman",
                "Dave Hollins",
                "Dave Hoskins",
                "Willie Kamm"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for San Diego Padres",
            "words": [
                "Kip Wells",
                "Manny Alexander",
                "Matty Alou",
                "Kurt Bevacqua"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Pittsburgh Pirates",
            "words": [
                "Tommy Thevenow",
                "Chris Gomez",
                "Harry Gumbert",
                "Dennis Lamp"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Arizona Diamondbacks",
            "words": [
                "Chris Capuano",
                "Lyle Overbay",
                "Vicente Padilla",
                "Justin Upton"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Pittsburgh Pirates",
            "words": [
                "Orlando Merced",
                "Dan Miceli",
                "Vicente Palacios",
                "Freddie Patek"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cleveland Indians",
            "words": [
                "Bob Seeds",
                "Duke Sims",
                "Tony Sipp",
                "Ed Taubensee"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Milwaukee Brewers",
            "words": [
                "Bob Coluccio",
                "Dick Davis",
                "Wilbur Howard",
                "Scott Karl"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Los Angeles Dodgers",
            "words": [
                "James Loney",
                "Jonathan Broxton",
                "Mike James",
                "Joel Hanrahan"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Colorado Rockies",
            "words": [
                "Aaron Cook",
                "Andy Burns",
                "David Lee",
                "Will Harris"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Cincinnati Reds",
            "words": [
                "Rob Mackowiak",
                "Pat Zachry",
                "Jimmy Nelson",
                "Tyler Green"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Milwaukee Brewers",
            "words": [
                "Yadiel Rivera",
                "J.J. Hardy",
                "Dale Sveum",
                "Kevin Kobel"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Cincinnati Reds",
            "words": [
                "Scott Terry",
                "Wayne Simpson",
                "Don Wengert",
                "Aaron Fultz"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago Cubs",
            "words": [
                "Alex Wilson",
                "Dennis Lamp",
                "Kerry Wood",
                "Ray Burris"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Francisco Giants",
            "words": [
                "Dave Rader",
                "Kevin Ritz",
                "Dave Rozema",
                "Nate Schierholtz"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Tampa Bay Devil Rays",
            "words": [
                "Dan Wheeler",
                "Chris Leroux",
                "Bryan Morris",
                "Dave Bush"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 13 seasons",
            "words": [
                "Fred Frankhouse",
                "Dave Roberts",
                "Mike Myers",
                "Bobby Bolin"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "Clyde Wright",
                "Claude Hendrix",
                "Bob Groom",
                "Scott Sullivan"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 15 seasons",
            "words": [
                "Jay Howell",
                "Dave Giusti",
                "George Brunet",
                "Steve Renko"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Mike Naymick",
                "Heath Murray",
                "Sam Nahem",
                "Kemp Wicker"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Pittsburgh Pirates",
            "words": [
                "Jim Bagby",
                "Brian Boehringer",
                "Tiny Bonham",
                "Jimmy Brown"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago Cubs",
            "words": [
                "Mel Wright",
                "Marvell Wynne",
                "Warren Brusstar",
                "Damon Buford"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Montreal Expos",
            "words": [
                "Bert Roberge",
                "Fred Scherman",
                "Chuck Taylor",
                "Scot Thompson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Texas Rangers",
            "words": [
                "Steve Kemp",
                "Mike Munoz",
                "Dale Murray",
                "Mike Napoli"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1927",
            "words": [
                "Homer Peel",
                "Wally Roettger",
                "Art Ruble",
                "Johnny Burnett"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1938",
            "words": [
                "Joe Beggs",
                "Tommy Butts",
                "Felix Evans",
                "Preacher Henry"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2006",
            "words": [
                "Dan Uggla",
                "Delmon Young",
                "Michael Bourn",
                "Roberto Hernández"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1907",
            "words": [
                "Al Shaw",
                "Harry Swacina",
                "Red Killefer",
                "William Louden"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Toronto Blue Jays: 8",
            "words": [
                "Alan Ashby",
                "Yorvit Torrealba",
                "Ken Macha",
                "Russ Adams"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago White Sox: 37",
            "words": [
                "Clay Touchstone",
                "Juan Minaya",
                "Ron Schueler",
                "Lloyd Allen"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "San Diego Padres: 57",
            "words": [
                "Dan Altavilla",
                "Justin Germano",
                "Luke Gregerson",
                "Jon Adkins"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Mets: 39",
            "words": [
                "Dan Wheeler",
                "Steve Reed",
                "Gary Gentry",
                "Tommy Moore"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Atlanta Braves",
            "words": [
                "Rafael Furcal",
                "Javy López",
                "Mike Lum",
                "Brian McCann"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Sam Tuivailala",
                "Jim Adduci",
                "Mike Barlow",
                "Michael Blazek"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cleveland Indians",
            "words": [
                "Ray Murray",
                "Mike Paul",
                "Rafael Pérez",
                "Zach Putnam"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Florida Marlins",
            "words": [
                "Burke Badenhop",
                "Vic Darensbourg",
                "Brian Daubach",
                "Jeremy Hermida"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Pittsburgh Pirates",
            "words": [
                "Radhames Liz",
                "Luis Márquez",
                "Pat Meares",
                "Craig Monroe"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago White Sox",
            "words": [
                "Britt Burns",
                "Rod Craig",
                "Mark Dalesandro",
                "Steve Dillard"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Yankees",
            "words": [
                "Masahiro Tanaka",
                "Bubba Trammell",
                "Kris Wilson",
                "Asher Wojciechowski"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Philadelphia Phillies",
            "words": [
                "Peanuts Lowrey",
                "Pat Neshek",
                "Danny Tartabull",
                "Paul Abbott"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Mets",
            "words": [
                "Mark Johnson",
                "Wayne Kirby",
                "Brandon Knight",
                "Kevin Kobel"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago Cubs",
            "words": [
                "Johnny Gill",
                "Ival Goodman",
                "Alex Grammas",
                "José Guzmán"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Cardinals",
            "words": [
                "Bill Baker",
                "José Bautista",
                "Brett Cecil",
                "Tim Conroy"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Minnesota Twins",
            "words": [
                "Bob Allison",
                "Earl Battey",
                "Neal Cotts",
                "Roy Smalley"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Diego Padres",
            "words": [
                "Chase Headley",
                "Doug DeCinces",
                "Shawn Hill",
                "Homer Bush"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Baltimore Orioles",
            "words": [
                "Brian Roberts",
                "Steven Brault",
                "Joe Borchard",
                "Don Hood"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago White Sox",
            "words": [
                "Dave Frost",
                "Josh Phegley",
                "Josh Rupe",
                "Joe Borowski"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Kansas City Royals",
            "words": [
                "Vic Darensbourg",
                "Dee Brown",
                "Mark Ellis",
                "Tim Spehr"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Houston Astros",
            "words": [
                "Tom Griffin",
                "Brandon Barnes",
                "Chris Singleton",
                "Jeff Reboulet"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Mets",
            "words": [
                "John Johnstone",
                "John Tudor",
                "Dave Cochrane",
                "Todd Jones"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Detroit Tigers",
            "words": [
                "Bryan Corey",
                "Burke Badenhop",
                "Bill Butler",
                "Charlie Puleo"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Yankees",
            "words": [
                "Carl Everett",
                "Ben Ford",
                "Bryan Mitchell",
                "Omir Santos"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1923",
            "words": [
                "Felton Stratton",
                "Hollis Thurston",
                "Otto Briggs",
                "Cliff Carter"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1951",
            "words": [
                "Sammy White",
                "Johnny Logan",
                "Dale Long",
                "Tom Morgan"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1971",
            "words": [
                "Chris Chambliss",
                "George Hendrick",
                "Doyle Alexander",
                "Chris Speier"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1991",
            "words": [
                "Frank Castillo",
                "Cal Eldred",
                "Rey Sánchez",
                "Vinny Castilla"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Cleveland Indians",
            "words": [
                "Joe Inglett",
                "Bruce Aven",
                "Tom Lampkin",
                "Cody Ransom"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Baltimore Orioles",
            "words": [
                "Michael Young",
                "Bob Melvin",
                "Will Venable",
                "Tom Walker"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Seattle Mariners",
            "words": [
                "Mike Gardiner",
                "Anthony Varvaro",
                "Brandon Maurer",
                "Willie Bloomquist"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago Cubs",
            "words": [
                "Joe Decker",
                "Matt Alexander",
                "Chuck Crim",
                "Brooks Kieschnick"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the San Diego Padres",
            "words": [
                "Jerry Turner",
                "Logan Forsythe",
                "Bill Long",
                "Tim Flannery"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Oakland Athletics",
            "words": [
                "George Hendrick",
                "Rick Lysander",
                "Gary Wayne",
                "Bob Lacey"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Montreal Expos",
            "words": [
                "John Russell",
                "T.J. Tucker",
                "Jeff Tabaka",
                "Jason Bay"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Houston Astros",
            "words": [
                "Tim Tolman",
                "Brian Hunter",
                "Jim Pankovits",
                "John Mayberry"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Houston Astros",
            "words": [
                "Jerry DaVanon",
                "J.R. Richard",
                "Ken Caminiti",
                "Bert Roberge"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Milwaukee Brewers",
            "words": [
                "Kevin Bass",
                "Ben Sheets",
                "Nomar Garciaparra",
                "Gary Sheffield"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the St. Louis Cardinals",
            "words": [
                "Brendan Ryan",
                "Mike Difelice",
                "Joe DeSa",
                "Rob Dibble"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Chicago White Sox",
            "words": [
                "Donn Pall",
                "Tom Gorzelanny",
                "Johnny Ruffin",
                "Ozzie Timmons"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Milwaukee Brewers",
            "words": [
                "Danny Frisella",
                "Éric Gagné",
                "Geremi González",
                "Jim Hannan"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Browns",
            "words": [
                "Willis Hudlin",
                "Frank O'Rourke",
                "Ralph Winegarner",
                "Luke Sewell"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Los Angeles Dodgers",
            "words": [
                "Chin-hui Tsao",
                "Michael Young",
                "Ken Boyer",
                "Enos Cabell"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Baltimore Orioles",
            "words": [
                "Tommy Matchick",
                "Mickey McGuire",
                "Ryan McGuire",
                "Francisco Meléndez"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Baltimore Orioles",
            "words": [
                "Steve Reed",
                "J.C. Romero",
                "Paul Shuey",
                "Billy Cox"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Kansas City Royals",
            "words": [
                "Dave Henderson",
                "Hideo Nomo",
                "Hawk Taylor",
                "Chris Young"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Boston Red Sox",
            "words": [
                "Matt Perisho",
                "Robert Person",
                "Nick Polly",
                "Rip Repulski"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Philadelphia Phillies",
            "words": [
                "Paul Hoover",
                "Brian Hunter",
                "Ken Lehman",
                "Bob Miller"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 19 seasons",
            "words": [
                "Yadier Molina",
                "Bob McClure",
                "Frank Thomas",
                "Steve Finley"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 11 seasons",
            "words": [
                "Larry Christenson",
                "Rabbit Warstler",
                "Paul Abbott",
                "Earl Wilson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Manny Corpas",
                "Johnny Marcum",
                "Zeke Bonura",
                "Emmanuel Burriss"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 10 seasons",
            "words": [
                "Carmelo Castillo",
                "Derrick May",
                "Jerry Johnson",
                "George Cuppy"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Hack Miller",
                "Reggie Harris",
                "Kevin Reimer",
                "Bob Harvey"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 12 seasons",
            "words": [
                "Jon Jay",
                "Luis Polonia",
                "Frank Malzone",
                "Bibb Falk"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 15 seasons",
            "words": [
                "Michael Cuddyer",
                "Charlie Moore",
                "Aaron Sele",
                "Earl Torgeson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Archie Ware",
                "Paul Richards",
                "Johnny Ray",
                "Terrence Long"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Washington Senators",
            "words": [
                "Bill Fahey",
                "John Kennedy",
                "Don Mincher",
                "Zoilo Versalles"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for New York Mets",
            "words": [
                "Chris Donnels",
                "Lenny Dykstra",
                "Pedro Feliciano",
                "Steve Henderson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Boston Red Sox",
            "words": [
                "Mickey McDermott",
                "Dustin Pedroia",
                "Rico Petrocelli",
                "Paul Quantrill"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Atlanta Braves",
            "words": [
                "Kelly Johnson",
                "Ryan Langerhans",
                "Adam LaRoche",
                "Félix Millán"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Toronto Blue Jays: 23",
            "words": [
                "Candy Maldonado",
                "José Bautista",
                "Dave Lemanczyk",
                "Mark Whiten"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Mets: 11",
            "words": [
                "Ramón Castro",
                "Aaron Ledesma",
                "Kelvin Chapman",
                "Wayne Kirby"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Arizona Diamondbacks: 41",
            "words": [
                "Greg Aquino",
                "Bobby Chouinard",
                "Junior Guerra",
                "Bret Prinz"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Milwaukee Brewers: 41",
            "words": [
                "Mike Birkbeck",
                "David Phelps",
                "Jim Slaton",
                "Mark Knudson"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cincinnati Reds",
            "words": [
                "Trevor Bell",
                "Tim Birtsas",
                "Brennan Boesch",
                "Harry Craft"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago Cubs",
            "words": [
                "Joel Peralta",
                "Bob Porterfield",
                "Lance Richbourg",
                "Andrew Romine"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Boston Red Sox",
            "words": [
                "Johnny Gooch",
                "Kevin Jarvis",
                "Bob Johnson",
                "John Kennedy"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Pittsburgh Pirates",
            "words": [
                "Gene Hermanski",
                "Mike Kingery",
                "Danny Murtaugh",
                "Junior Noboa"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Atlanta Braves",
            "words": [
                "Tony Tarasco",
                "Cecil Upshaw",
                "Jonny Venters",
                "Duane Ward"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Seattle Mariners",
            "words": [
                "Chris Snelling",
                "Bob Stoddard",
                "Jesús Sucre",
                "Mac Suzuki"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Montreal Expos",
            "words": [
                "Jerry Goff",
                "Tom Gorman",
                "Don Hahn",
                "Todd Haney"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Chicago White Sox",
            "words": [
                "Ken Silvestri",
                "Chris Stewart",
                "Bob Weiland",
                "Ken Berry"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Chi-Chi Olivo",
                "Joe Frazier",
                "John Henry",
                "Ed Blake"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Ralph Wyatt",
                "Jordan Schafer",
                "Marino Pieretti",
                "Johnny Kucks"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 11 seasons",
            "words": [
                "Milt Gaston",
                "Ken Phelps",
                "Tommy Holmes",
                "Mike Phillips"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 17 seasons",
            "words": [
                "Brandon Phillips",
                "Felton Snow",
                "Chuck Finley",
                "Royce Clayton"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Oakland Athletics",
            "words": [
                "Frank Menechino",
                "Daniel Mengden",
                "Izzy Molina",
                "Josh Outman"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "Ricky Horton",
                "José Jiménez",
                "Josh Kinney",
                "Brooks Lawrence"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Philadelphia Phillies",
            "words": [
                "Dennis Bennett",
                "Stew Bolen",
                "Rick Bosetti",
                "Ed Bouchee"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Cincinnati Reds",
            "words": [
                "Kristopher Negrón",
                "Frank Pastore",
                "Pokey Reese",
                "Wally Roettger"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Cardinals",
            "words": [
                "Paul Kilgus",
                "Les Lancaster",
                "Art Mahaffey",
                "Billy McCool"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Colorado Rockies",
            "words": [
                "Manny Delcarmen",
                "Mike Kelly",
                "Aaron Ledesma",
                "Wilton López"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for New York Giants",
            "words": [
                "Nap Reyes",
                "Bill Rigney",
                "Fuzz White",
                "Artie Wilson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Detroit Tigers",
            "words": [
                "Rufe Gentry",
                "Enrique González",
                "Gail Harris",
                "Ned Harris"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Atlanta Braves",
            "words": [
                "David Cortés",
                "Randall Delgado",
                "Adrian Devine",
                "Preston Hanna"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Houston Colt .45s",
            "words": [
                "John Bateman",
                "Brock Davis",
                "Chris Zachary",
                "Sonny Jackson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Cardinals",
            "words": [
                "J.D. Drew",
                "Harvey Haddix",
                "Bill Hallahan",
                "Ed Heusser"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Milwaukee Brewers",
            "words": [
                "Brian Banks",
                "Chuck Crim",
                "Alex Diaz",
                "Tom Hausman"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Houston Astros: 6",
            "words": [
                "Harry Spilman",
                "Ed Taubensee",
                "Paul Bako",
                "Greg Gross"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Houston Astros: 9",
            "words": [
                "Humberto Quintero",
                "Troy Afenir",
                "Julio González",
                "Cliff Johnson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Oakland Athletics: 52",
            "words": [
                "Tim Hudson",
                "Ryan Buchter",
                "Troy Afenir",
                "Ed Núñez"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cincinnati Reds: 38",
            "words": [
                "Pete Harnisch",
                "Troy Afenir",
                "Gary Majewski",
                "Bud Byerly"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Boston Red Sox: 50",
            "words": [
                "Quintin Berry",
                "Stan Williams",
                "Rich Gedman",
                "Dave Sax"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Baltimore Orioles: 34",
            "words": [
                "Quintin Berry",
                "Ozzie Virgil",
                "Jake Arrieta",
                "Pat Rapp"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Chicago Cubs: 5",
            "words": [
                "Quintin Berry",
                "Reed Johnson",
                "Bruce Edwards",
                "Albert Almora"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Milwaukee Brewers: 2",
            "words": [
                "Quintin Berry",
                "José Valentín",
                "Kurt Bevacqua",
                "Randy Ready"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Gary Allenson",
                "Ed Bouchee",
                "Dad Clarke",
                "JB Shuck"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Hardie Henderson",
                "Bobby Ramos",
                "Dane Sardinha",
                "Jocko Fields"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 17 seasons",
            "words": [
                "Jeff Kent",
                "Chris Chambliss",
                "Alan Ashby",
                "David Cone"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 8 seasons",
            "words": [
                "Jimmy Hill",
                "Tom Lawless",
                "Vance Worley",
                "Subby Byas"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Milwaukee Brewers",
            "words": [
                "Scott Sullivan",
                "Kevin Barker",
                "Jeff D'Amico",
                "Lary Sorensen"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Pittsburgh Pirates",
            "words": [
                "Craig Reynolds",
                "Willie Greene",
                "Adrian Brown",
                "John Wehner"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Los Angeles Dodgers",
            "words": [
                "Eric Stults",
                "Rick Sutcliffe",
                "Phil Nevin",
                "Mark Melancon"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Mets",
            "words": [
                "Ty Wigginton",
                "Craig Worthington",
                "Darin Erstad",
                "Joe Klink"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Los Angeles Dodgers: 47",
            "words": [
                "Howie Kendrick",
                "Jerry Sands",
                "Chad Moeller",
                "Jesse Orosco"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Los Angeles Dodgers: 46",
            "words": [
                "Mike Hartley",
                "Paul Quantrill",
                "Jim Bruske",
                "Nelson Liriano"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cleveland Indians: 20",
            "words": [
                "Jeromy Burnitz",
                "Bob Kuzava",
                "Otis Nixon",
                "Rubén Amaro"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Los Angeles Dodgers: 49",
            "words": [
                "Bryan Corey",
                "Brian Falkenborg",
                "Carlos Marmól",
                "Tom Niedenfuer"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Houston Astros",
            "words": [
                "Karl Rhodes",
                "Kirk Saarloos",
                "Brian Boehringer",
                "Wayne Twitchell"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Florida Marlins",
            "words": [
                "Ross Gload",
                "Tom Koehler",
                "Josh Beckett",
                "Seth Etherton"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Baltimore Orioles",
            "words": [
                "Gregg Zaun",
                "Garrett Stephenson",
                "Frank Tepedino",
                "Dave Otto"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Texas Rangers",
            "words": [
                "Mike Stanley",
                "Robb Nen",
                "Dean Palmer",
                "Michael Kirkman"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1987",
            "words": [
                "Billy Ripken",
                "Pete Smith",
                "Jeff Blauser",
                "Mike Macfarlane"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1962",
            "words": [
                "Dave Giusti",
                "Ed Kirkpatrick",
                "Ken McMullen",
                "Bob Bailey"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1871",
            "words": [
                "Doug Allison",
                "Denny Mack",
                "Ned Cuthbert",
                "Bob Ferguson"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2010",
            "words": [
                "Tyson Ross",
                "Brian Schlitter",
                "Mark Trumbo",
                "Jonny Venters"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Mike Thurman",
                "Joe Orengo",
                "Harry Parker",
                "Jason Bergmann"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 15 seasons",
            "words": [
                "Tito Francona",
                "Jerry Morales",
                "Rico Carty",
                "Charlie Bennett"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 9 seasons",
            "words": [
                "Albie Pearson",
                "Buddy Kerr",
                "Andy Coakley",
                "Joe Benz"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 3 seasons",
            "words": [
                "Ted Bond",
                "John McGuinness",
                "Red Charleston",
                "Jack Neagle"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "New York Giants: 27",
            "words": [
                "Hy Vandenberg",
                "Willard Marshall",
                "Al Glossop",
                "Tony Cuccinello"
            ],
            "difficulty": 1
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Cleveland Indians: 49",
            "words": [
                "Nerio Rodríguez",
                "Tony Sipp",
                "Mel Harder",
                "Bob Milacki"
            ],
            "difficulty": 2
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Minnesota Twins: 31",
            "words": [
                "Greg Gagne",
                "Rick Reed",
                "Jim Perry",
                "Ken Brett"
            ],
            "difficulty": 3
        },
        {
            "Display": "Have played for the same team under the same uniform number.",
            "category": "Detroit Tigers: 50",
            "words": [
                "Tim Pugh",
                "George Zuverink",
                "Eric Munson",
                "Andrew Miller"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Toronto Blue Jays",
            "words": [
                "Ryan Langerhans",
                "Doug Linton",
                "Kevin Millar",
                "Randy Moffitt"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cincinnati Reds",
            "words": [
                "Jimmy Haynes",
                "Aaron Holbert",
                "Andy Kosco",
                "Roger Nelson"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Philadelphia Phillies",
            "words": [
                "Bradley Zimmer",
                "Jim Adduci",
                "Henderson Alvarez III",
                "Stan Andrews"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Detroit Tigers",
            "words": [
                "Rusty Kuntz",
                "Wendell Magee",
                "Karl Olson",
                "Tim Tolman"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Texas Rangers",
            "words": [
                "Rafael Bournigal",
                "Roy Howell",
                "Lewis Brinson",
                "Laynce Nix"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the New York Yankees",
            "words": [
                "Jeff Manto",
                "Austin Jackson",
                "Pat Clements",
                "Tyler Lyons"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Los Angeles Dodgers",
            "words": [
                "Edwin Jackson",
                "Tim Scott",
                "Brett Tomko",
                "Bob Stanley"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players first drafted by the same team",
            "category": "Players drafted by the Philadelphia Phillies",
            "words": [
                "Aaron Altherr",
                "Marlon Byrd",
                "Terry Harmon",
                "Bill Champion"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 6 seasons",
            "words": [
                "Tim Nordbrook",
                "Mike Hubbard",
                "Tony Abreu",
                "Anthony Young"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 5 seasons",
            "words": [
                "Paul Dixon",
                "Larry Bearnarth",
                "Roy Partee",
                "Garnett Blair"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 7 seasons",
            "words": [
                "Shelley Duncan",
                "Bun Hayes",
                "Billy Ashley",
                "Paul LaPalme"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 17 seasons",
            "words": [
                "Rollie Fingers",
                "Ernie Lombardi",
                "Amos Otis",
                "Steve O'Neill"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Baltimore Orioles",
            "words": [
                "Yorkis Pérez",
                "Pete Smith",
                "Chris Widger",
                "Wilson Betemit"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Cincinnati Reds",
            "words": [
                "Barry Larkin",
                "Horace Lisenbee",
                "José Rijo",
                "Russ Springer"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for California Angels",
            "words": [
                "Merv Rettenmund",
                "Vern Ruhle",
                "Max Venable",
                "Butch Wynegar"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for St. Louis Cardinals",
            "words": [
                "Brayan Peña",
                "Carl Scheib",
                "Scott Spiezio",
                "Gabe White"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago Cubs",
            "words": [
                "Leo Gómez",
                "Robert Gsellman",
                "Joe Hatten",
                "Don Hurst"
            ],
            "difficulty": 1
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Los Angeles Dodgers",
            "words": [
                "Terry Whitfield",
                "Todd Worrell",
                "Don Aase",
                "Josh Beckett"
            ],
            "difficulty": 2
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Washington Nationals",
            "words": [
                "Leon Culberson",
                "Ryan Drese",
                "Alex Escobar",
                "Erick Fedde"
            ],
            "difficulty": 3
        },
        {
            "Display": "Players having played their last MLB game for the same team",
            "category": "Played their last game for Chicago White Sox",
            "words": [
                "Bret Prinz",
                "Zach Putnam",
                "Rob Scahill",
                "Pat Seerey"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for St. Louis Browns",
            "words": [
                "Tony Giuliani",
                "Tom Jenkins",
                "Chad Kimsey",
                "Earl McNeely"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Kansas City Royals",
            "words": [
                "Buddy Biancalana",
                "Keith Creel",
                "Cheslor Cuthbert",
                "Luke Farrell"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Los Angeles Dodgers",
            "words": [
                "Ed Rakow",
                "Jason Repko",
                "R.J. Reynolds",
                "Adam Riggs"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Texas Rangers",
            "words": [
                "Jonathan Johnson",
                "Rubén Mateo",
                "Kevin Reimer",
                "Robbie Ross"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 15 seasons",
            "words": [
                "Clyde McCullough",
                "Derrek Lee",
                "Carl Furillo",
                "Sam Thompson"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 9 seasons",
            "words": [
                "Rube Curry",
                "Drew Stubbs",
                "Scott Williamson",
                "Alvin Davis"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 23 seasons",
            "words": [
                "Carl Yastrzemski",
                "Henry Aaron",
                "Rogers Hornsby",
                "Rabbit Maranville"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played the same number of seasons in their MLB careers",
            "category": "Players who played 4 seasons",
            "words": [
                "Harry Barnes",
                "George Riley",
                "Ford Smith",
                "Adam Stern"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Oakland Athletics",
            "words": [
                "Don Wengert",
                "Daric Barton",
                "Chris Carter",
                "Bobby Crosby"
            ],
            "difficulty": 1
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Texas Rangers",
            "words": [
                "Roy Smalley",
                "Pedro Strop",
                "Rick Waits",
                "Jerry Don Gleaton"
            ],
            "difficulty": 2
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Minnesota Twins",
            "words": [
                "Mike Kinnunen",
                "Matt LeCroy",
                "Jeff Manship",
                "Joe Mays"
            ],
            "difficulty": 3
        },
        {
            "Display": "Played their first MLB game for the same team",
            "category": "Played their first game for Kansas City Royals",
            "words": [
                "Billy Buckner",
                "Lance Carter",
                "Louis Coleman",
                "Onix Concepción"
            ],
            "difficulty": 4
        }
    ],
    [
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2003",
            "words": [
                "Chad Gaudin",
                "Édgar González",
                "Mike Gonzalez",
                "Laynce Nix"
            ],
            "difficulty": 1
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1943",
            "words": [
                "Tommy Byrne",
                "George Kell",
                "Andy Seminick",
                "Andy Pafko"
            ],
            "difficulty": 2
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 2014",
            "words": [
                "Austin Adams",
                "Aaron Altherr",
                "Justin Bour",
                "Jon Edwards"
            ],
            "difficulty": 3
        },
        {
            "Display": "Debuted MLB the same year",
            "category": "Debuted MLB in 1973",
            "words": [
                "Ken Griffey",
                "Dave Parker",
                "Brian Downing",
                "George Brett"
            ],
            "difficulty": 4
        }
    ]
];
