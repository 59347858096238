import React from "react";

import { generateEmojiGrid } from "../../../lib/game-helpers";
import CountdownToNextPuzzle from "../../CountdownToNextPuzzle";
import ShareScoreButton from "../../ShareScoreButton";
import BaseModal from "../BaseModal";
import { GameStatusContext } from "../../../providers/GameStatusProvider";
import { PuzzleDataContext } from "../../../providers/PuzzleDataProvider";
import { Button } from "../../ui/button";

function ViewResultsModal() {
  const { submittedGuesses } = React.useContext(GameStatusContext);
  const { gameData } = React.useContext(PuzzleDataContext);

  return (
    <BaseModal
      title=""
      trigger={
        <Button variant="submit" className="w-full" children={"View today's results"} />
      }
      initiallyOpen={false}
      showActionButton={false}
      footerElements={
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <ShareScoreButton buttonText={"Share your Score!"} style={{ flex: 1, margin: '0 5px', padding: '10px', fontSize: '16px' }} />
          <button 
            style={{ 
              flex: 1,
              background: '#007bff', 
              color: 'white', 
              border: 'none', 
              margin: '0 5px',
              padding: '8px', 
              borderRadius: '5px', 
              cursor: 'pointer',
              fontSize: '16px' 
            }} 
            onClick={() => window.open('https://www.baseball-connections.com', '_blank')}
          >
            Play more games
          </button>
        </div>
      }
    >
      <div className="flex flex-col place-content-center">
        <p className="text-center font-[600]">
          Your submissions are represented below
        </p>
        <span className="text-center whitespace-pre mb-2">
          {"\n"}
          {generateEmojiGrid(gameData, submittedGuesses)}
        </span>
        <CountdownToNextPuzzle />
      </div>
    </BaseModal>
  );
}

export default ViewResultsModal;
