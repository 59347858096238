import React from "react";

import InfoModal from "../modals/InfoModal";

import { Twitter } from "lucide-react"; // Import Twitter icon from lucide-react

function Header() {

  return (

    <header className="flex justify-between items-center">

      <h1 className="font-space-mono flex-grow">Connections - MLB Edition</h1>

      <div className="flex items-center">

        <a href="https://twitter.com/baseballconnec" target="_blank" rel="noopener noreferrer" className="mr-4">

          <Twitter />

        </a>

        <InfoModal />

      </div>

    </header>

  );

}

export default Header;
