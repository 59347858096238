import React from "react";
import BaseModal from "../BaseModal";

import { generateEmojiGrid } from "../../../lib/game-helpers";
import ShareScoreButton from "../../ShareScoreButton";
import CountdownToNextPuzzle from "../../CountdownToNextPuzzle";
import { PuzzleDataContext } from "../../../providers/PuzzleDataProvider";

function GameWonModal({ open, submittedGuesses }) {
  const { gameData } = React.useContext(PuzzleDataContext);

  return (
    <BaseModal
      title="Congratulations!"
      initiallyOpen={open}
      footerElements={
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <ShareScoreButton style={{ flex: 1, margin: '0 5px', padding: '10px', fontSize: '16px' }} />
          <button 
            style={{ 
              flex: 1,
              background: '#007bff', 
              color: 'white', 
              border: 'none', 
              margin: '0 5px',
              padding: '8px', 
              borderRadius: '5px', 
              cursor: 'pointer',
              fontSize: '16px' 
            }} 
            onClick={() => window.open('https://www.baseball-connections.com/', '_blank')}
          >
            Play more games
          </button>
        </div>
      }
      showActionButton={false}
    >
      <p>{"Here's your result:"}</p>
      <div className="justify-center">
        {/* the whitespace: pre style makes the emoji grid appear with new lines character */}
        <span className="text-center whitespace-pre">
          {"\n"}
          {generateEmojiGrid(gameData, submittedGuesses)}
        </span>
        <CountdownToNextPuzzle />
      </div>
    </BaseModal>
  );
}

export default GameWonModal;
