import React from "react";
import { MAX_MISTAKES } from "../../../lib/constants";
import { HelpCircle } from "lucide-react";
import BaseModal from "../BaseModal";

function InfoModal() {
  const modalContentStyle = {
    /* Add your general styling here */
  };

  const titleStyle = {
    fontWeight: 'bold',
    paddingBottom: '10px',
  };

  const subtitleStyle = {
    fontWeight: 'bold',
    paddingTop: '10px',
  };

  const ulStyle = {
    paddingBottom: '10px',
  };

  const colorDescriptionsStyle = {
    paddingTop: '10px',
  };

  return (
    <BaseModal
      title=""
      trigger={<HelpCircle className="mr-4" />}
      initiallyOpen={false}
      actionButtonText="Got it!"
    >
      <div style={modalContentStyle}>
        <div id="close-help" className="close-x"></div>
        <div id="inner-help">
          <h2 style={titleStyle}>HOW TO PLAY CONNECTIONS - MLB EDITION</h2>
          <p style={subtitleStyle}>
            Find 4 groups of four players that have something in common.
          </p>
          <ul style={ulStyle}>
            <li>- Select four players' names and press 'Submit' to check if your combination is correct.</li>
            <li>- Find all the groups without making more than {MAX_MISTAKES} mistakes!</li>
          </ul>
          <p style={subtitleStyle}>Examples of categories</p>
          <ul style={ulStyle}>
            <li>PLAYERS SHARING THE SAME BIRTH YEAR</li>
            <li>PLAYERS SHARING THE SAME UNIFORM NUMBER</li>
          </ul>
          <p>
            Each puzzle has exactly one solution. Watch out for players that seem to belong to multiple categories!
          </p>
          <div id="color-descriptions" style={colorDescriptionsStyle}>
            <p>
              Each group is associated with a color, representing a different category.
            </p>
            <div>
              <ul className="help-emojis">
               <li>🟩</li>
               <li>🟨</li>
               <li>🟦</li>
               <li>🟥</li>
              </ul>
            </div>
          </div>
          <div id="feedback">
            <p>
              Do you have any feedback or suggestions? Let us know!
              <a
                id="feedback-general"
                href="https://iteratehq.com/nytimes/646f92ea79f79000014aff6a"
                target="_blank"
              >
              </a>
              !
            </p>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}

export default InfoModal;
